import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'antd';

import ScanHandler from '@/pages/scanner-thirdparty/components/ScanHandler';
import AntHeading from '@/components/controls/heading/antHeading';
import {ReactComponent as ScanLogo} from '@/assets/icons/scanner/scan.svg'

import s from './index.module.scss'
import AntText from '@/components/controls/text/antText';
import ScanTable from '@/pages/scanner-thirdparty/components/ScanTable';
import { usePageFocusTracker } from '@/common/hooks/usePageFocusTracker';
import useAlert from '@/common/hooks/useAlert';
import { useMutation } from 'react-query';
import { deliveryProviderTypeEnum } from '@/common/models/order';
import OrderService from '@/common/api/OrderService';
import ScanConfirmModal from '@/pages/scanner-thirdparty/components/ScanConfirmModal';


const ScannerThirdPartyView = () => {
  const { t } = useTranslation()
  const isPageFocused = usePageFocusTracker();
  const { openNotification, destroyNotification } = useAlert()

  const { mutate: updateAllOrders} = useMutation(() => OrderService.patchOrderItems({ data: orders?.map(order => ({
      orderItemId: order?.id,
      deliveryProviderType: deliveryProviderTypeEnum?.THIRD_PARTY_DELIVERY
    }))
  }), {
    onSettled: () => setTableLoading(false),
    onSuccess: () => {
      setIsConfirmModalOpen(true);
    },
    onError: () => openNotification('error', t('scannerThirdPartyPage.errors.badResponse.header'), t('scannerThirdPartyPage.errors.badResponse.text'), 0)

  })
  const [orders, setOrders] = useState([]);

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const clearPage = () => {
    setOrders([])
  }

  useEffect(() => {
    if(!isPageFocused && !isConfirmModalOpen) {
      openNotification('error', t('scannerThirdPartyPage.errors.noFocus.header'), t('scannerThirdPartyPage.errors.noFocus.text'), 0, 'noFocus')
    }
    if(isPageFocused) {
      destroyNotification('noFocus')
    }
  }, [destroyNotification, isConfirmModalOpen, isPageFocused, openNotification, t])

  return (
    <div id={s['scan-page']}>
      <ScanConfirmModal key={`${orders?.length}`} orders={orders} isConfirmModalOpen={isConfirmModalOpen} setIsConfirmModalOpen={setIsConfirmModalOpen} clearPage={clearPage}/>
      <ScanHandler orders={orders} setOrders={setOrders} />
      <AntHeading size={'h2'} style={{marginTop: '25px'}}>{t('scannerThirdPartyPage.header')}</AntHeading>
      <AntHeading size={'h3'} style={{marginTop: '34px', marginBottom: '16px', lineHeight: '17px'}}>{t('scannerThirdPartyPage.scanQRTitle')}</AntHeading>
      <section className={s['qr-section']}>
        <ScanLogo key={isPageFocused} style={{ color: isPageFocused ? '#7B57DF' : '#3d3d3d', cursor: 'pointer', height: 136, width: 136}} />
        <List split={false} rootClassName={s['steps']}>
          <List.Item className={s['custom-list-item']} key={`first ${isPageFocused}`}>
            <AntText size={'body'} weight={'medium'} color={'contentMain'} style={!isPageFocused ? {color: 'var(--accent-main)', padding: 0} : {padding: 0}}>
              {t('scannerThirdPartyPage.steps.first')}
            </AntText>
          </List.Item>
          <List.Item className={s['custom-list-item']} key={`second ${isPageFocused}`}>
            <AntText size={'body'} weight={'medium'} color={'contentMain'} style={isPageFocused && orders?.length < 1 ? {color: 'var(--accent-main)', padding: 0} : {padding: 0}}>
              {t('scannerThirdPartyPage.steps.second')}
            </AntText>
          </List.Item>
          <List.Item className={s['custom-list-item']} key={`third ${isPageFocused}`}>
            <AntText size={'body'} weight={'medium'} color={'contentMain'} style={isPageFocused && orders?.length > 0 ? {color: 'var(--accent-main)', padding: 0} : {padding: 0}}>
              {t('scannerThirdPartyPage.steps.third')}
            </AntText>
          </List.Item>
        </List>
      </section>
      <section className={s['table-section']}>
        {
          isPageFocused && (
            <ScanTable
              key={orders}
              isConfirmModalOpen={isConfirmModalOpen}
              tableLoading={tableLoading}
              orders={orders}
              setOrders={setOrders}
              updateAllOrders={updateAllOrders}
            />
          )
        }
      </section>
    </div>
  );
};

export default ScannerThirdPartyView;