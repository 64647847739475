import React, { CSSProperties, useState } from 'react';
import Select from "react-select";
import {Button} from 'react-bootstrap';

const customSelectStyles = {
    control: (provided: CSSProperties, { isFocused, selectProps: { success, error } }) => {
        return {
            ...provided,
            fontSize: '14px !important',
            borderColor: isFocused ? 'var(--accent-main)' : error ? '#EB5769' : success ? '#5DC983' : 'var(--content-dop-light)',
            borderRadius: '6px',
            height: '32px !important',
            minHeight: '32px !important',
            alignItems: 'center !important',
            transition: 'all 0.2s ease',
            boxShadow: isFocused ? '0 0 0 2px var(--accent-main-light)' : 'none',
            '&:hover': {
                borderColor: 'var(--accent-main)',
            },
        };
    },
    menuList: (provided) => ({
        ...provided,
        maxHeight: '135px',
        '::-webkit-scrollbar': {
            width: '6px',
        },
        '::-webkit-scrollbar-thumb': {
            background: 'var(--accent-main-light)',
            borderRadius: '4px',
        },
        '::-webkit-scrollbar-track': {
            background: '#f0f0f0',
        },
    }),
    singleValue: (provided: CSSProperties) => {
        return {
            ...provided,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 'normal',
        };
    },
    menu: (provided: CSSProperties) => ({
        ...provided,
        zIndex: 2,
        color: 'var(--content-main)',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 'normal',
    }),
    input: (provided: CSSProperties) => ({
        ...provided,
        color: 'var(--content-main)',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: 'normal',
        padding: '0 !important',
        margin: '0 !important',
    }),
    valueContainer: (provided: CSSProperties) => ({
        ...provided,
        padding: '0 !important',
        margin: '0 12px !important',
    }),
    indicatorSeparator: (provided: CSSProperties) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided: CSSProperties) => ({
        ...provided,
        height: '32px !important',
        paddingTop: '5px',
    }),
    indicatorsContainer: (provided: CSSProperties) => ({
        ...provided,
        minHeight: '32px !important',
        height: '32px !important',
    }),
    placeholder: (provided: CSSProperties, {isFocused}) => {
        return {
            ...provided,
            color: isFocused ? 'var(--content-dop-light)' : 'var(--content-main)',
        }
    },
}

interface RouteCellSelectProps {
    items: any[],
    disabled?: boolean,
    defaultItem?: string,
    className?: string,
    onChange: (cell: string | null) => void,
    menuPlacement?: 'auto' | 'top'
}

const RouteCellSelect = ({items, disabled = false, className = "", defaultItem = "", onChange, menuPlacement = 'auto'}: RouteCellSelectProps) => {
    const options = items.map(buildOptions)

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (value) => {
        setInputValue(value);
    };

    function buildOptions (item) {
        return {
            label: item,
            value: item
        }
    }

    const handleOptionChange = (option) => {
        onChange(option?.value)
    }

    return (
        <div className={className}>
            {disabled ? (
                    <Button
                        disabled={true}
                        className={"w-100 btn-outline-primary text-dark"}
                    >{defaultItem ?? "Не выбрано"}</Button>
                ) : (
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    styles={customSelectStyles}
                    isDisabled={disabled}
                    placeholder={defaultItem}
                    menuPlacement={menuPlacement}
                    onInputChange={handleInputChange}
                    onChange={handleOptionChange}
                    inputValue={inputValue}
                    blurInputOnSelect={true}
                    isSearchable={true}
                    isClearable={false}
                    name="color"
                    options={options}
                />
            )}
        </div>
    )
};



export default RouteCellSelect;