import { ruOrdersImport } from '@/common/constants/locales/ru/ordersImport';
import { ruOptions } from '@/common/constants/locales/ru/options';
import { ruOrdersPage } from '@/common/constants/locales/ru/ordersPage';
import { ruCommon } from '@/common/constants/locales/ru/common';
import { ruTelegram } from '@/common/constants/locales/ru/telegram';
import { ruReports } from '@/common/constants/locales/ru/reports';
import { ruOrderCreate } from '@/common/constants/locales/ru/orderCreate';
import { couriersPage } from '@/common/constants/locales/ru/couriersPage';
import { authPage } from '@/common/constants/locales/ru/auth';
import { clientHandbookPage } from '@/common/constants/locales/ru/clientHandbook';
import { ruScannerCourierPage } from '@/common/constants/locales/ru/scannerSection/courierPage';
import { ruScannerCellPage } from '@/common/constants/locales/ru/scannerSection/cellPage';
import { scannerThirdPartyPage } from '@/common/constants/locales/ru/scannerSection/thirdPartyPage';

export const ruTranslation = {
  translation: {
    ...ruCommon,
    clientHandbookPage: clientHandbookPage,
    orderCreate: ruOrderCreate,
    scannerCourierPage: ruScannerCourierPage,
    scannerCellPage: ruScannerCellPage,
    scannerThirdPartyPage: scannerThirdPartyPage,
    ordersImportPage: ruOrdersImport,
    options: ruOptions,
    ordersPage: ruOrdersPage,
    telegram: ruTelegram,
    reportPage: ruReports,
    couriersPage: couriersPage,
    authPage: authPage
  },
};