import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Button, Col, Container, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PaperClipOutlined } from '@ant-design/icons';


import OrderItemChangeHistoryView from './OrderItemChangeHistoryView';
import SearchableSelect from './SearchableSelect';
import OrderService from '@/common/api/OrderService';
import { OrderItemChangeApiResponse } from '@/common/models/api/v0/order.dto';
import { Roles } from '@/common/constants/roles';
import { Statuses } from '@/common/constants/statuses';
import DefaultButton from '@/components/buttons/DefaultButton';
import {
  clientAccess,
  deliveryTypeOptions,
  fields,
  parcelTypeOptions,
  RouteCellOptions,
} from '@/pages/order/options';
import { orderStatusMap } from '@/components/controls/order-action/actions';
import { buildOrderItemUpdateDto, checkIfEmpty, convertToTimeZone, getDeliveryPartyCity } from '@/common/utils/utils';
import CityAsyncSelect from '@/components/controls/select/CityAsyncSelect';
import { Formats } from '@/common/constants/dateFormat';
import { cellOptions } from '../../options';
import RouteCellSelect from '@/pages/order/components/ordersView/RouteCellSelect';
import useAuth from '@/common/hooks/useAuth';
import ConfirmModal from '@/pages/order/components/ConfirmModal';
import { OrderItem } from '@/common/models/order';
import { DeliveryProcessType } from '@/common/constants/deliveryProcess';
import { usePrint } from '@/common/hooks/usePrint';

const OrderItemDetailsView: FC<OrderItemDetailsProps> = ({orderItemId, handleClose, readOnly = true, role = Roles.Client, onEdit = () => {}}) => {
  const { data: orderItem, isLoading: orderItemLoading } = useQuery<OrderItem>(['order-courier', orderItemId], () => {
    if (orderItemId) return OrderService.getById(orderItemId);
  }, { cacheTime: 0, staleTime: 0, enabled: !!orderItemId });
  const {data, isLoading: historyLoading, error, refetch} = useQuery<OrderItemChangeApiResponse>('orderItemChangeHistory',
    () => OrderService.getOrderItemChangeHistory(orderItem?.id), {enabled: !!orderItem?.id})

  const { setStickers, setNotes } = usePrint()

  const [isFormLoading, setIsFormLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false)

  const [status, setStatus] = useState(null)
  const [directChangeStatus, setDirectChangeStatus] = useState(null)
  const showConfirmModal = (status, directChangeStatus) => {
    if(directChangeStatus && hasAnyRole([Roles.Admin])) {
      return true
    } else if(status && hasAnyRole([Roles.Manager]) 
      && (values?.deliveryProcessType === DeliveryProcessType.THIRD_PARTY_PROVIDER 
        || !Statuses.courierAssignableStatuses[orderItem?.status])) {
      return true
    }
    return false
  }

  const [deliveryType, setDeliveryType] = useState(deliveryTypeOptions.filter(item => item.value === orderItem?.deliveryType)[0])
  const [parcelType, setParcelType] = useState(parcelTypeOptions.filter(item => item.value === orderItem?.parcelType)[0])
  const [values, setValues] = useState({
    ...orderItem,
    estimatedDeliveryDate: convertToTimeZone(orderItem?.estimatedDeliveryDate, false, 'yyyy-MM-dd\'T\'HH:mm:ss')
  });

  const {t} = useTranslation()
  const {hasAnyRole} = useAuth()
  const navigate = useNavigate()


  useEffect(() => {
    setValues({
      ...orderItem,
      estimatedDeliveryDate: convertToTimeZone(orderItem?.estimatedDeliveryDate, false, 'yyyy-MM-dd\'T\'HH:mm:ss')
    })
    setDeliveryType(deliveryTypeOptions.filter(item => item.value === orderItem?.deliveryType)[0])
    setParcelType(parcelTypeOptions.filter(item => item.value === orderItem?.parcelType)[0])
  }, [orderItem])

  const cancelEdit = () => {
    setValues({
      ...orderItem,
      estimatedDeliveryDate: convertToTimeZone(orderItem?.estimatedDeliveryDate, false, 'yyyy-MM-dd\'T\'HH:mm:ss'),
    });
    setEditMode(false);
    setDeliveryType(deliveryTypeOptions.filter(item => item.value === orderItem?.deliveryType)[0]);
    setParcelType(parcelTypeOptions.filter(item => item.value === orderItem?.parcelType)[0]);
    setStatus(null);
    setErrors(null);
    setDirectChangeStatus(null);
  };

  const { refetch: handleChangeStatus } =
    useQuery(['orderItemChangeStatus'], async () => {
        setIsFormLoading(true);
        if (status !== null) {
          return await OrderService.changeStatus(values.id, status.event);
        }
        if (role === Roles.Admin && directChangeStatus !== null) {
          return await OrderService.manualStatusChange(values.id, directChangeStatus);
        }
      },
      {
        enabled: false,
        onSuccess: () => onEdit(),
        onError: (error) => setErrors(error),
        onSettled: () => setIsFormLoading(false),
      });

  const { refetch: handleApprove } = useQuery(['orderItemApprove'], async () => {
      setIsFormLoading(true);
      await OrderService.changeStatus(values.id, 'CONFIRM_NEW_ORDER');
      setValues(prev => ({ ...prev, status: 'READY_FOR_PICKUP' }));
    },
    {
      enabled: false,
      onSuccess: () => refetch(),
      onError: (error) => setErrors(error),
      onSettled: () => setIsFormLoading(false),
    });

  const { refetch: handleDelete } = useQuery(['orderItemDelete'], async () => {
      setIsFormLoading(true);
      await OrderService.deleteById(orderItem?.id);
    },
    {
      enabled: false,
      onSuccess: () => onEdit(),
      onError: (error) => setErrors(error),
      onSettled: () => {
        setIsFormLoading(false);
      },
    });

  const { refetch: handleSave } = useQuery(['orderItemSave'], async () => {
      setIsFormLoading(true);
      const updatedOrderItem = buildOrderItemUpdateDto(values, deliveryType, parcelType);
      await OrderService.updateOrderItem(updatedOrderItem, values.id);
      if(status !== null && Statuses.courierAssignableStatuses[orderItem?.status]){
        await OrderService.changeStatus(orderItemId, status?.event)
      }
      setValues({ ...updatedOrderItem });
      setEditMode(false);
    },
    {
      enabled: false,
      onSuccess: () => onEdit(),
      onError: (error) => setErrors(error),
      onSettled: () => setIsFormLoading(false),
    });

  const getAccess = (field: string = null): boolean => {
    if (!editMode) {
      return false;
    }
    if (Statuses.courierAssignableStatuses[field] && role !== Roles.Admin) {
      return false;
    }
    if (role === Roles.Manager || role === Roles.Admin) {
      return true;
    }
    if (orderItem?.status !== 'NEW') {
      return false;
    }
    const isAccessible = clientAccess.includes(field);
    return !!isAccessible;
  };

  const getStatusDropdownElements = () => {
    const elements = Statuses.adminStatusMap[orderItem?.deliveryProcessType]
    return role === Roles.Admin && elements
      ? elements.map(e => (
        <Dropdown.Item
          key={e}
          className='w-100 text-center'
          onClick={() => {
            setDirectChangeStatus(e);
          }}
        >
          {t(`options.orderStatus.${e}`)}
        </Dropdown.Item>
      ))
      : orderStatusMap[values?.status]?.events
        .filter(e => !e.processTypes || e.processTypes.includes(values.deliveryProcessType))
        .map(e => (
          <Dropdown.Item
            key={values?.status}
            className='w-100 text-center'
            onClick={() => {
              setStatus(e);
            }}
          >
            {t(`orderEvents.${e.event}`)}
          </Dropdown.Item>
        ));
  };

  const isChangeStatusDisabled = () => {
    if (hasAnyRole([Roles.Admin])) {
      return false
    }

    if (values?.deliveryProcessType === DeliveryProcessType.THIRD_PARTY_PROVIDER 
      && values?.status === 'READY_FOR_DELIVERY'
      && orderStatusMap[values?.status]) {
        return false
    }

    if (!orderStatusMap[values?.status] || Statuses.courierAssignableStatuses[values?.status]) {
      return true
    }
  }

  const senderName = orderItem?.deliverySender?.isCompany
    ? orderItem?.deliverySender?.company?.name
    : orderItem?.deliverySender?.contactName;

  const receiverName = orderItem?.deliveryReceiver?.isCompany
    ? orderItem?.deliveryReceiver?.company?.name
    : orderItem?.deliveryReceiver?.contactName;
    return (
      <Modal show={!!orderItemId} onHide={handleClose} dialogClassName="wide-modal">
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title className="pl-5">{t('ordersPage.details.detailsHeader')} {orderItem?.id}</Modal.Title>
          <Button variant="light" onClick={handleClose}>
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Container>
              <Form>
                <Row>
                  <Col md={6} className="mb-3">
                    <Row>
                      <Form.Label>{t('ordersPage.details.createDate')}: {orderItem
                        ? (orderItem?.createDate && convertToTimeZone(orderItem?.createDate, false, Formats.DATE_DMY_TIME))
                        : ''}
                      </Form.Label>
                      <Form.Label>{t('ordersPage.details.arriveDate')}: {orderItem
                        ? (orderItem?.arriveDate && convertToTimeZone(orderItem?.arriveDate, false, Formats.DATE_DMY_SLASH))
                        : ''}
                      </Form.Label>
                    </Row>
                    <Row><Form.Label>{t('ordersPage.details.sender')}</Form.Label></Row>
                    <Form.Label>{t('ordersPage.details.companyName')}:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={checkIfEmpty(values?.deliverySender?.company?.name)}
                      onChange={(e) =>
                        setValues(prev => ({
                          ...prev,
                          deliverySender: {
                            ...prev.deliverySender,
                            company: {
                              ...prev.deliverySender.company,
                              name: e.target.value,
                            },
                          },
                        }))
                      }
                      disabled={true}
                      placeholder={senderName}
                    />
                    <Form.Label>{t('ordersPage.details.senderCity')}:</Form.Label>
                    <CityAsyncSelect
                      key={values?.deliverySender}
                      name="sender"
                      readOnly={readOnly}
                      defaultValue={{
                        value: checkIfEmpty(values?.deliverySender?.address?.city?.id),
                        label: checkIfEmpty(values?.deliverySender?.address?.city?.name),
                      }}
                      onChange={(option) => setValues(prev => ({
                        ...prev,
                        deliverySender: {
                          ...prev.deliverySender,
                          address: {
                            ...prev.deliverySender.address,
                            city: {
                              id: option.value,
                              name: option.label,
                            },
                          },
                        },
                      }))
                      }
                    />
                    <Form.Label>{t('ordersPage.details.street')}:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={checkIfEmpty(values?.deliverySender?.address?.street)}
                      onChange={(e) =>
                        setValues(prev => ({
                          ...prev,
                          deliverySender: {
                            ...prev.deliverySender,
                            address: {
                              ...prev.deliverySender.address,
                              street: e.target.value,
                            },
                          },
                        }))
                      }
                      disabled={true}
                      placeholder={orderItem?.deliverySender?.address?.addressString}
                    />
                    <Form.Label>{t('ordersPage.details.building')}:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={checkIfEmpty(values?.deliverySender?.address?.building)}
                      onChange={(e) =>
                        setValues(prev => ({
                          ...prev,
                          deliverySender: {
                            ...prev.deliverySender,
                            address: {
                              ...prev.deliverySender.address,
                              building: e.target.value,
                            },
                          },
                        }))
                      }
                      disabled={true}
                      placeholder={orderItem?.deliverySender?.address?.addressString}
                    />
                    <Form.Label>{t('ordersPage.details.apartment')}:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={checkIfEmpty(values?.deliverySender?.address?.apartment)}
                      onChange={(e) =>
                        setValues(prev => ({
                          ...prev,
                          deliverySender: {
                            ...prev.deliverySender,
                            address: {
                              ...prev.deliverySender.address,
                              apartment: e.target.value,
                            },
                          },
                        }))
                      }
                      disabled={true}
                      placeholder={orderItem?.deliverySender?.address?.addressString}
                    />
                    <Form.Label>{t('ordersPage.details.addressComments')}:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={checkIfEmpty(values?.deliverySender?.address?.comment)}
                      onChange={(e) =>
                        setValues(prev => ({
                          ...prev,
                          deliverySender: {
                            ...prev.deliverySender,
                            address: {
                              ...prev.deliverySender.address,
                              comment: e.target.value,
                            },
                          },
                        }))
                      }
                      disabled={true}
                      placeholder={orderItem?.deliverySender?.address?.comment}
                    />
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Label>{t('ordersPage.details.contactName')}:</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={checkIfEmpty(values?.deliverySender?.contactName)}
                          onChange={(e) =>
                            setValues(prev => ({
                              ...prev,
                              deliverySender: {
                                ...prev.deliverySender,
                                contactName: e.target.value,
                              },
                            }))
                          }
                          disabled={true}
                          placeholder={orderItem?.deliverySender?.contactName}
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Label>{t('ordersPage.details.phone')}:</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={checkIfEmpty(values?.deliverySender?.phone)}
                          onChange={(e) =>
                            setValues(prev => ({
                              ...prev,
                              deliverySender: {
                                ...prev.deliverySender,
                                phone: e.target.value,
                              },
                            }))
                          }
                          disabled={true}
                          placeholder={orderItem?.deliverySender?.phone}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Form.Label>{t('ordersPage.details.deliveryType')}:</Form.Label>
                      <Dropdown className="w-100">
                        <Dropdown.Toggle
                          className="w-100 border-rounded-1"
                          id={"deliveryType-dropdown"}
                          variant={"primary"}
                          disabled={!getAccess(fields.senderDeliveryType)}
                        >
                          {deliveryType === "*" ? (t('ordersPage.details.dropdowns.deliveryType.label')) : (deliveryType?.text)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          className="w-100 p-0"
                        >
                          {deliveryTypeOptions.slice(1, deliveryTypeOptions.length).map((type) =>
                            <Dropdown.Item
                              key={type.value}
                              className="w-100 text-center"
                              onClick={() => {
                                setDeliveryType(type)
                              }}>{type.text}</Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Row>
                    <Row>
                      <Form.Label>{t('ordersPage.details.parcelType')}:</Form.Label>
                      <Dropdown className="w-100">
                        <Dropdown.Toggle
                          className="w-100 border-rounded-1"
                          id={"parcelType-dropdown"}
                          variant={"primary"}
                          disabled={!getAccess(fields.senderParcelType)}
                        >
                          {parcelType === "*" ? (t('ordersPage.details.dropdowns.parcelType.label')) : (parcelType?.text)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          className="w-100 p-0"
                        >
                          {parcelTypeOptions.slice(1, parcelTypeOptions.length).map((type) =>
                            <Dropdown.Item
                              key={type.value}
                              className="w-100 text-center"
                              onClick={() => {
                                setParcelType(type)
                              }}>{type.text}</Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Row>
                    {
                      (hasAnyRole([Roles.Admin, Roles.Manager])) && (
                        <Row>
                          <Col md={6} className="mb-3">
                            <Form.Label>{t('ordersPage.details.route')}:</Form.Label>
                            <RouteCellSelect
                              key={values?.route}
                              items={Object.keys(RouteCellOptions)}
                              disabled={!getAccess()}
                              defaultItem={values?.route ?? t('ordersPage.details.dropdowns.notSelected')}
                              onChange={(route) => {
                                if (route) {
                                  setValues(prev => ({
                                    ...prev,
                                    route: route,
                                    cell: RouteCellOptions[route]
                                  }))
                                } else {
                                  setValues(prev => ({
                                    ...prev,
                                    route: null,
                                  }))
                                }

                              }}
                            />
                          </Col>
                          <Col md={6} className="mb-3">
                            <Form.Label>{t('ordersPage.details.cell')}:</Form.Label>
                            <RouteCellSelect
                              key={values?.cell}
                              items={cellOptions}
                              disabled={!getAccess()}
                              defaultItem={values?.cell ?? t('ordersPage.details.dropdowns.notSelected')}
                              onChange={(cell) => {
                                if (cell) {
                                  setValues(prev => ({
                                    ...prev,
                                    cell: cell
                                  }))
                                } else
                                  setValues(prev => ({
                                    ...prev,
                                    cell: null,
                                  }))
                              }}
                            />
                          </Col>
                        </Row>
                      )
                    }
                    {
                      (hasAnyRole([Roles.Manager, Roles.Admin])) && (
                        <Row>
                          <Col md={12} className="mb-3">
                            <Form.Label>{t('deliveryProcessTypes.title')}:</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              value={values?.deliveryProcessType === DeliveryProcessType.THIRD_PARTY_PROVIDER ? t('ordersPage.details.qazPost'): t('ordersPage.details.courier')}
                              disabled={true}
                              placeholder={senderName}
                            />
                          </Col>
                        </Row>
                      )
                    }
                    {
                      (orderItem?.deliveryProcessType === DeliveryProcessType.THIRD_PARTY_PROVIDER && hasAnyRole([Roles.Manager, Roles.Admin])) && (
                        <Row class='d-flex'>
                          <Col md={6}>
                            <Form.Label>{t('ordersPage.details.thirdPartyStatus')}:</Form.Label>
                            <br/>
                            <Form.Label
                              className={'fw-semibold'}
                              type='text'
                              placeholder={checkIfEmpty(orderItem?.thirdPartyProviderStatus)}
                            >
                              {
                                orderItem?.thirdPartyTrackingNumber ? (
                                  orderItem?.thirdPartyProviderStatus || t('ordersPage.details.thirdPartyStatusError')
                                ) : (
                                  t('ordersPage.details.thirdPartyNotAssigned')
                                )
                              }
                            </Form.Label>
                          </Col>
                          <Col md={6}>
                            <Form.Label>
                              {t('ordersPage.details.thirdPartyTrackingNumber')}:
                            </Form.Label>
                            <Form.Label
                              className={'fw-semibold'}
                              type='text'
                              placeholder={checkIfEmpty(orderItem?.thirdPartyTrackingNumber)}
                            >
                              {
                                  orderItem?.thirdPartyTrackingNumber ? (
                                    <Link
                                      className={'text-primary cursor'}
                                      to={`https://qazpost.kz/ru/t/${orderItem?.thirdPartyTrackingNumber}`}
                                    >
                                      {orderItem?.thirdPartyTrackingNumber}
                                    </Link>
                                  ) : ( t('ordersPage.details.thirdPartyNotAssigned'))
                              }
                            </Form.Label>
                          </Col>
                        </Row>
                      )
                    }
                    {
                      hasAnyRole([Roles.Admin, Roles.Manager]) && (
                        <Row>
                          <Col md={12} className="mb-3">
                            <Form.Label>{t('ordersPage.details.courier')}:</Form.Label>
                            <SearchableSelect
                              key={orderItem?.courier}
                              orderItem={orderItem}
                              placeHolder={`${orderItem?.courier?.firstName} ${orderItem?.courier?.lastName}`}
                              showCourierDelivered={hasAnyRole([Roles.Manager, Roles.Admin])}
                              readOnly={!getAccess()
                                || !Statuses.courierChangeStatuses.includes(orderItem?.status)}
                              onChange={(selectedOption) => {
                                if (typeof selectedOption === "string") {
                                  orderItem.courier = selectedOption;
                                } else {
                                  orderItem.courier = selectedOption.value;
                                }
                                setValues(prev => ({...prev,
                                  courier: orderItem.courier,
                                  status: Statuses.courierAssignableStatuses[orderItem?.status] ?? values?.status
                                }))
                                setStatus(orderStatusMap[orderItem?.status]?.events[0])
                              }}
                            />
                          </Col>
                        </Row>
                      )
                    }
                    <Row>
                      <Col md={8} className="mb-3">
                        <Form.Label>{t('ordersPage.details.deliveryDate')}:</Form.Label>
                        <Form.Control
                          className="w-100 d-flex justify-content-center"
                          required
                          type="datetime-local"
                          value={checkIfEmpty(values?.estimatedDeliveryDate)}
                          onChange={(e) => {
                            setValues(prev => ({
                              ...prev,
                              estimatedDeliveryDate: e.target.value
                            }))
                          }
                          }
                          disabled={!getAccess()}
                        />
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Label>{t('ordersPage.details.cost')}:</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={checkIfEmpty(values?.calculatedCost)}
                          onChange={(e) =>
                            setValues(prev => ({...prev,
                              calculatedCost: e.target.value
                            }))
                          }
                          disabled={!getAccess()}
                          placeholder={orderItem?.calculatedCost}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <DefaultButton onClick={() => navigate(`/order-attachments/${orderItem?.id}`, { state: {
                            courier: orderItem?.courier
                          }})}>
                          <PaperClipOutlined /> <span className={"mx-2"}>{t('ordersPage.details.attachmentButton')}</span>
                        </DefaultButton>
                      </Col>
                      <Col md={12} className="mb-3">
                        <Form.Label>{t('ordersPage.details.comments')}:</Form.Label>
                        <Form.Control
                          id={"comments"}
                          name={"comments"}
                          placeholder={checkIfEmpty(orderItem?.comments)}
                          as={"textarea"}
                          style={{minHeight: "5.5rem"}}
                          disabled={!getAccess(fields.receiverOrderComments)}
                          onChange={(e) => {
                            setValues(prev => ({...prev,
                              comments: e.target.value
                            }))
                          }}
                          value={checkIfEmpty(values?.comments)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className={"position-relative"}>
                        {
                          ((deleteModalShow && hasAnyRole([Roles.Admin, Roles.Manager])) && (
                            <ConfirmModal
                              isLoading={isFormLoading}
                              onSuccessText={t('ordersPage.details.cancel')}
                              onCancelText={t('ordersPage.details.delete')}
                              onSuccess={() => {
                                setDeleteModalShow(false);
                              }}
                              onCancel={async () => {
                                setDeleteModalShow(false);
                                await handleDelete();
                                navigate('/orders')
                              }}
                              styles={{top: '-12dvh', right: '1vw'}}
                            >
                              {t('ordersPage.details.deleteConfirm.header')}
                              <br/><span className={"text-danger"}>{t('ordersPage.details.deleteConfirm.body')}</span>
                            </ConfirmModal>
                          ))
                        }
                        {
                          editMode && (
                            (hasAnyRole([Roles.Client]) && values?.status === "NEW")
                            ||
                            hasAnyRole([Roles.Admin, Roles.Manager]))
                          && (
                            <Row className="d-flex justify-content-between">
                              {
                                (errors !== null) && (<Col md={10}><span className="d-flex text-danger">{errors?.message}</span></Col>)
                              }

                              <Col md={4} className="d-flex justify-content-start">
                                <DefaultButton
                                  key={"saveOrder"}
                                  variant={"success"}
                                  onClick={handleSave}
                                  isLoading={isFormLoading}
                                >{t('ordersPage.details.save')}
                                </DefaultButton>
                              </Col>
                              <Col md={7} className="d-flex justify-content-end">
                                <DefaultButton
                                  key={"cancelOrder"}
                                  variant={"danger"}
                                  onClick={cancelEdit}
                                  isLoading={isFormLoading}
                                >{t('ordersPage.details.cancelEdit')}
                                </DefaultButton>
                              </Col>
                            </Row>
                          )
                        }
                        {
                          !editMode && (
                            (hasAnyRole([Roles.Client]) && values?.status === "NEW")
                            ||
                            hasAnyRole([Roles.Admin, Roles.Manager]))
                          && (
                            <div className="d-flex justify-content-between">
                              <DefaultButton
                                key={"editOrder"}
                                variant={"primary"}
                                onClick={() => {
                                  setEditMode(true)
                                }}
                                disabled={!(role === Roles.Manager || role === Roles.Admin ? true : (values?.status === "NEW" && true))}
                                isLoading={isFormLoading}
                              >{t('ordersPage.details.edit')}
                              </DefaultButton>
                              {
                                (hasAnyRole([Roles.Admin, Roles.Manager])) && (
                                  <DefaultButton
                                    key={"deleteOrder"}
                                    variant={"danger"}
                                    onClick={() => setDeleteModalShow(true)}
                                    isLoading={isFormLoading}
                                  >
                                    {t('ordersPage.details.delete')}
                                  </DefaultButton>
                                )
                              }
                            </div>
                          )
                        }
                      </Col>
                      <Col md={9} className="mt-3">
                        <div className="d-flex">
                          {hasAnyRole([Roles.Manager, Roles.Admin]) && Statuses.printAccessibleStatuses.includes(values?.status) && (
                            <DefaultButton
                              bordered
                              variant="light"
                              onClick={() => {
                                setStickers([orderItem])
                                window.open(`${window.location.origin}/order-stickers-print`, '_blank');
                              }}
                            >
                              {t('ordersPage.details.printSticker')}
                            </DefaultButton>
                          )}
                          {values?.status === "NEW" && role === Roles.Client && (
                            <DefaultButton
                              className="mx-2"
                              key={"editOrder"}
                              variant={"primary"}
                              isLoading={isFormLoading}
                              onClick={handleApprove}
                              disabled={!(values.status === "NEW")}
                            >
                              {t('ordersPage.details.confirmOrder')}
                            </DefaultButton>
                          )}
                        </div>
                        <div className="py-2">
                          <DefaultButton
                            bordered
                            variant="light"
                            onClick={() => {
                              setNotes([orderItem])
                              window.open(`${window.location.origin}/orders-notes-print`, '_blank');
                            }}
                          >
                            {t('ordersPage.details.printNote')}
                          </DefaultButton>
                        </div>
                      </Col>
                    </Row>

                  </Col>

                  <Col md={6} className="mb-3">
                    <Row>
                      <Col md={6} className="mb-4">
                        <Form.Label>{t('ordersPage.details.orderId')}: {orderItem?.id}</Form.Label>
                      </Col>
                    </Row>
                    <Row><Form.Label>{t('ordersPage.details.receiver')}</Form.Label></Row>
                    <Row>
                      <Form.Label>{t('ordersPage.details.companyName')}:</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={checkIfEmpty(values?.deliveryReceiver?.company?.name)}
                        onChange={(e) =>
                          setValues(prev => ({
                            ...prev,
                            deliveryReceiver: {
                              ...prev.deliveryReceiver,
                              company: {
                                ...prev.deliveryReceiver.company,
                                name: e.target.value
                              }
                            }
                          }))
                        }
                        disabled={!getAccess(fields.receiverName)}
                        placeholder={receiverName}
                      />
                      <Form.Label>{t('ordersPage.details.receiverCity')}:</Form.Label>
                      <div className="p-0">
                        <CityAsyncSelect
                          key={values?.deliveryReceiver}
                          name="receiver"
                          defaultValue={{
                            value: checkIfEmpty(values?.deliveryReceiver?.address?.city?.id),
                            label: checkIfEmpty(values?.deliveryReceiver?.address?.city?.name),
                          }}
                          readOnly={!getAccess(fields.receiverAddress)}
                          onChange={(option) =>
                            setValues(prev => ({
                              ...prev,
                              deliveryReceiver: {
                                ...prev.deliveryReceiver,
                                address: {
                                  ...prev.deliveryReceiver.address,
                                  city: {
                                    id: option.value,
                                    name: option.label,
                                  },
                                },
                              },
                            }))
                          }
                        />
                      </div>
                      <Form.Label>{t('ordersPage.details.street')}:</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={checkIfEmpty(values?.deliveryReceiver?.address?.street)}
                        onChange={(e) =>
                          setValues(prev => ({
                            ...prev,
                            deliveryReceiver: {
                              ...prev.deliveryReceiver,
                              address: {
                                ...prev.deliveryReceiver.address,
                                street: e.target.value,
                              },
                            },
                          }))
                        }
                        disabled={!getAccess(fields.receiverAddress)}
                        placeholder={orderItem?.deliveryReceiver?.address?.addressString}
                      />
                      <Form.Label>{t('ordersPage.details.building')}:</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={checkIfEmpty(values?.deliveryReceiver?.address?.building)}
                        onChange={(e) =>
                          setValues(prev => ({
                            ...prev,
                            deliveryReceiver: {
                              ...prev.deliveryReceiver,
                              address: {
                                ...prev.deliveryReceiver.address,
                                building: e.target.value,
                              },
                            },
                          }))
                        }
                        disabled={!getAccess(fields.receiverAddress)}
                        placeholder={orderItem?.deliveryReceiver?.address?.addressString}
                      />
                      <Form.Label>{t('ordersPage.details.apartment')}:</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={checkIfEmpty(values?.deliveryReceiver?.address?.apartment)}
                        onChange={(e) =>
                          setValues(prev => ({
                            ...prev,
                            deliveryReceiver: {
                              ...prev.deliveryReceiver,
                              address: {
                                ...prev.deliveryReceiver.address,
                                apartment: e.target.value,
                              },
                            },
                          }))
                        }
                        disabled={!getAccess(fields.receiverAddress)}
                        placeholder={orderItem?.deliveryReceiver?.address?.addressString}
                      />
                      <Form.Label>{t('ordersPage.details.addressComments')}:</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={checkIfEmpty(values?.deliveryReceiver?.address?.comment)}
                        onChange={(e) =>
                          setValues(prev => ({
                            ...prev,
                            deliveryReceiver: {
                              ...prev.deliveryReceiver,
                              address: {
                                ...prev.deliveryReceiver.address,
                                comment: e.target.value,
                              },
                            },
                          }))
                        }
                        disabled={!getAccess(fields.receiverAddressComments)}
                        placeholder={orderItem?.deliveryReceiver?.address?.comment}
                      />
                      <Row>
                        <Col md={6} className="mb-3">
                          <Form.Label>{t('ordersPage.details.contactName')}:</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            value={checkIfEmpty(values?.deliveryReceiver?.contactName)}
                            onChange={(e) =>
                              setValues(prev => ({
                                ...prev,
                                deliveryReceiver: {
                                  ...prev.deliveryReceiver,
                                  contactName: e.target.value,
                                },
                              }))
                            }
                            disabled={!getAccess(fields.receiverContactName)}
                            placeholder={orderItem?.deliveryReceiver?.contactName}
                          />
                        </Col>
                        <Col md={6} className="mb-3">
                          <Form.Label>{t('ordersPage.details.phone')}:</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            value={checkIfEmpty(values.deliveryReceiver?.phone)}
                            onChange={(e) =>
                              setValues(prev => ({
                                ...prev,
                                deliveryReceiver: {
                                  ...prev.deliveryReceiver,
                                  phone: e.target.value,
                                },
                              }))
                            }
                            disabled={!getAccess(fields.receiverPhone)}
                            placeholder={orderItem?.deliveryReceiver?.phone}
                          />
                        </Col>
                      </Row>

                      <hr/>
                      {
                        (hasAnyRole([Roles.Admin, Roles.Manager])) && (
                          <Col md={12} className="mb-3">
                            <Dropdown className="w-100">
                              <Dropdown.Toggle
                                className="w-100 border-rounded-1"
                                id={"changeStatus-dropdown"}
                                variant={'primary'}
                                disabled={isChangeStatusDisabled()}
                              >
                                {
                                  (role === Roles.Admin) ? (
                                      (directChangeStatus === null) ?
                                        (t('ordersPage.details.dropdowns.changeStatus.label'))
                                        :
                                        (t(`options.orderStatus.${directChangeStatus}`))
                                    )
                                    :
                                    status === null ? (t('ordersPage.details.dropdowns.changeStatus.label')) : (status?.name)
                                }
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                className="w-100 p-0"
                              >
                                {getStatusDropdownElements()}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                        )
                      }
                      <div className="position-relative z-3 mx-3 mb-2">
                        {
                          (showConfirmModal(status, directChangeStatus)) &&  (
                            <ConfirmModal
                              onCancel={() => {
                                setStatus(null)
                                setDirectChangeStatus(null)
                              }}
                              onSuccess={handleChangeStatus}
                              isLoading={isFormLoading}
                            >
                              {status && t(`orderEvents.${status?.event}`, {
                                from_city: getDeliveryPartyCity(orderItem?.deliverySender),
                                to_city: getDeliveryPartyCity(orderItem?.deliveryReceiver),
                              })}
                              {directChangeStatus && t(`orderStatus.${directChangeStatus}`, {
                                from_city: getDeliveryPartyCity(orderItem?.deliverySender),
                                to_city: getDeliveryPartyCity(orderItem?.deliveryReceiver),
                              })}
                            </ConfirmModal>
                          )
                        }
                      </div>

                      <Row style={{maxHeight: '570px', overflowY: 'auto'}}>

                        <Row><Form.Label>{t('ordersPage.details.changeHistory')}</Form.Label></Row>
                        <OrderItemChangeHistoryView
                          orderItemData={orderItem}
                          orderItemChanges={data ? data.orderItemChanges : []}
                          isLoading={orderItemLoading || historyLoading}
                          error={error}
                        />
                      </Row>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    );
}

interface OrderItemDetailsProps {
  orderItemId: number,
  handleClose: () => void,
  readOnly?: boolean,
  role?: Roles,
  onEdit: () => {}}

export default OrderItemDetailsView