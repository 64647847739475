import React from 'react';
import { observer } from 'mobx-react-lite'
import {Tab, Tabs} from 'react-bootstrap'
import {useTranslation} from "react-i18next";

import {CourierStatusMode, Statuses} from "@/common/constants/statuses";
import OrderList from './order/OrderList'
import OrderItemList from './order-item/OrderItemList'
import useCourier from '@/common/hooks/useCourier';
import { courierDeliveryTypeOptions } from '@/common/storage/courierStore';
import { SelectOption } from '@/common/models/util';



const OrderSection = observer(() => {
    const { t } = useTranslation()
    const { selectionTab, setSelectionTab, searchType, setSearchType } = useCourier()

    const handleTabChange = (tab: CourierStatusMode) => {
        setSelectionTab(tab)
        const currIndex = courierDeliveryTypeOptions[selectionTab]?.findIndex((option: SelectOption) => option.value === searchType)
        setSearchType(courierDeliveryTypeOptions[tab][currIndex]?.value)
    }

    const tabComponents = {
        [CourierStatusMode.PICKUP]: <OrderList />,
        [CourierStatusMode.DELIVERY]: <OrderItemList />,
    }

    return (
        <div>
            <div className="mt-3">
                <Tabs
                    className="my-2"
                    id="courier-orders-tab"
                    activeKey={selectionTab}
                    onSelect={handleTabChange}
                    fill
                >
                    {Object.keys(Statuses.courierAppStatusLists).map((tabKey, index) => (
                        <Tab 
                            key={index} 
                            eventKey={tabKey} 
                            tabClassName={selectionTab === tabKey
                                ? "text-primary" 
                                : "bg-dark bg-opacity-10 text-black"
                            }
                            title={t(`courierTabs.${tabKey}`)}
                        />
                    ))}
                </Tabs>
            </div>
            {tabComponents[selectionTab]}
        </div>
    )
})

export default OrderSection