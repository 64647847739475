import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'antd';

import ScanHandler from '@/pages/scanner-courier/components/ScanHandler';
import AntHeading from '@/components/controls/heading/antHeading';
import {ReactComponent as ScanLogo} from '@/assets/icons/scanner/scan.svg'

import s from './index.module.scss'
import AntText from '@/components/controls/text/antText';
import SearchableSelect from '@/pages/order/components/ordersView/SearchableSelect';
import { QrType } from '@/common/models/qr';
import AntLabel from '@/components/controls/antLabel/antLabel';
import ScanTable from '@/pages/scanner-courier/components/ScanTable';
import { usePageFocusTracker } from '@/common/hooks/usePageFocusTracker';
import useAlert from '@/common/hooks/useAlert';
import { useMutation } from 'react-query';
import { OrderItem } from '@/common/models/order';
import OrderService from '@/common/api/OrderService';
import { Statuses } from '@/common/constants/statuses';
import { orderStatusMap } from '@/components/controls/order-action/actions';
import { convertToISO, convertToTimeZone } from '@/common/utils/utils';
import { Formats } from '@/common/constants/dateFormat';
import AntDatePicker from '@/components/controls/antDatePicker/antDatePicker';
import ScanConfirmModal from '@/pages/scanner-courier/components/ScanConfirmModal';
import { scanInstance } from '@/common/models/util';


const ScannerView = () => {
  const { t } = useTranslation()
  const isPageFocused = usePageFocusTracker();
  const { openNotification, destroyNotification } = useAlert()

  const {mutateAsync: assignCourier} = useMutation(async (order: OrderItem) => {
      await OrderService.updateOrderItem({courier: scanInstance?.instance, estimatedDeliveryDate: convertToTimeZone(convertToISO(estimatedDate, 'ru'), false, Formats.DATE_TIMESTAMP)}, order?.id)
      if(Statuses.courierAssignableStatuses[order?.status]) {
        await OrderService.changeStatus(order?.id, orderStatusMap[order?.status]?.events.find(event => event?.processTypes.includes(order?.deliveryProcessType))?.event)
      }
  });
  const [orders, setOrders] = useState([]);
  const [scanInstance, setScanInstance] = useState<scanInstance>(null);

  const [error, setError] = useState({date: '', scan: ''});
  const [estimatedDate, setEstimatedDate] = useState<string>(null);

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const clearPage = () => {
    setScanInstance(null)
    setEstimatedDate(null)
    setOrders([])
  }

  const updateAllOrders = async () => {
    try {
      setTableLoading(true)
      await Promise.all(
        orders.map(async (order) => {
            await assignCourier(order);
        })
      );
      setIsConfirmModalOpen(true)
    } catch (e) {
      clearPage()
      openNotification('error', t('scannerCourierPage.errors.badResponse.header'), t('scannerCourierPage.errors.badResponse.text'), 0)
    } finally {
      setTableLoading(false)
    }
  };

  const handleSelectCourier = (selectedOption) => {
    setScanInstance({
      id: selectedOption?.value?.id,
      type: QrType.COURIER,
      instance: selectedOption?.value,
    });
    setError(prev => ({
      ...prev,
      scan: ''
    }));
  };
  const handleChangeDate = (_, dateString) => {
    setEstimatedDate(dateString);
    setError(prev => ({
      ...prev,
      date: !dateString ? t('scannerCourierPage.errors.noDateField') : ''
    }))
  }

  useEffect(() => {
    if(!isPageFocused && !isConfirmModalOpen) {
      openNotification('error', t('scannerCourierPage.errors.noFocus.header'), t('scannerCourierPage.errors.noFocus.text'), 0, 'noFocus')
    }
    if(isPageFocused) {
      destroyNotification('noFocus')
    }
  }, [destroyNotification, isConfirmModalOpen, isPageFocused, openNotification, t])

  const orderItemForCourier = { courier: scanInstance?.instance }

  return (
    <div id={s['scan-page']}>
      <ScanConfirmModal key={`${orders?.length} ${scanInstance?.id} ${estimatedDate}`} courier={scanInstance?.instance} orders={orders} estimatedDate={estimatedDate} isConfirmModalOpen={isConfirmModalOpen} setIsConfirmModalOpen={setIsConfirmModalOpen} clearPage={clearPage}/>
      <ScanHandler updateAllOrders={updateAllOrders} scanInstance={scanInstance} setScanInstance={setScanInstance} orders={orders} setOrders={setOrders} setError={(errText: string) => {
        setError(prev => ({
          ...prev,
          scan: errText,
        }))
      }}/>
      <AntHeading size={'h2'} style={{marginTop: '25px'}}>{t('scannerCourierPage.header')}</AntHeading>
      <AntHeading size={'h3'} style={{marginTop: '34px', marginBottom: '16px', lineHeight: '17px'}}>{t('scannerCourierPage.scanQRTitle')}</AntHeading>
      <section className={s['qr-section']}>
        <ScanLogo key={isPageFocused} style={{ color: isPageFocused ? '#7B57DF' : '#3d3d3d', cursor: 'pointer', height: 136, width: 136}} />
        <List split={false} rootClassName={s['steps']}>
          <List.Item className={s['custom-list-item']} key={`first ${isPageFocused}`}>
            <AntText size={'body'} weight={'medium'} color={'contentMain'} style={!isPageFocused ? {color: 'var(--accent-main)', padding: 0} : {padding: 0}}>
              {t('scannerCourierPage.steps.first')}
            </AntText>
          </List.Item>
          <List.Item className={s['custom-list-item']} key={`second ${isPageFocused}`}>
            <AntText size={'body'} weight={'medium'} color={'contentMain'} style={isPageFocused && !scanInstance?.id ? {color: 'var(--accent-main)', padding: 0} : {padding: 0}}>
              {t('scannerCourierPage.steps.second')}
            </AntText>
          </List.Item>
          <List.Item className={s['custom-list-item']} key={`third ${isPageFocused}`}>
            <AntText size={'body'} weight={'medium'} color={'contentMain'} style={isPageFocused && scanInstance?.id && orders?.length < 1 ? {color: 'var(--accent-main)', padding: 0} : {padding: 0}}>
              {t('scannerCourierPage.steps.third')}
            </AntText>
          </List.Item>
          <List.Item className={s['custom-list-item']} key={`fourth ${isPageFocused}`}>
            <AntText size={'body'} weight={'medium'} color={'contentMain'} style={isPageFocused && scanInstance?.id && orders?.length > 0 ? {color: 'var(--accent-main)', padding: 0} : {padding: 0}}>
              {t('scannerCourierPage.steps.fourth')}
            </AntText>
          </List.Item>
        </List>
      </section>
      <section className={s['manual-set']}>
        <div className={s['search-courier']}>
          <AntLabel required>{t('scannerCourierPage.manual.courier')}</AntLabel>
          <SearchableSelect key={`${scanInstance?.instance} ${isConfirmModalOpen}`}
                            success={scanInstance?.instance && !isConfirmModalOpen && t('scannerCourierPage.manual.courierChosen')}
                            error={error?.scan}
                            readOnly={false}
                            isLoading={false}
                            orderItem={isConfirmModalOpen ? {} : orderItemForCourier}
                            onChange={handleSelectCourier}
                            styles={
                              {
                                control: (provided: CSSProperties, { isFocused, selectProps: { success, error } }) => {
                                  return {
                                    ...provided,
                                    fontSize: '14px !important',
                                    borderColor: isFocused ? 'var(--accent-main)' : error ? '#EB5769' : success ? '#5DC983' : 'var(--content-dop-light)',
                                    borderRadius: '6px',
                                    height: '32px !important',
                                    minHeight: '32px !important',
                                    alignItems: 'center !important',
                                    transition: 'all 0.2s ease',
                                    boxShadow: isFocused ? '0 0 0 2px var(--accent-main-light)' : 'none',
                                    '&:hover': {
                                      borderColor: 'var(--accent-main)',
                                    },
                                  };
                                },
                                menuList: (provided) => ({
                                  ...provided,
                                  maxHeight: '135px',
                                  '::-webkit-scrollbar': {
                                    width: '6px',
                                  },
                                  '::-webkit-scrollbar-thumb': {
                                    background: 'var(--accent-main-light)',
                                    borderRadius: '4px',
                                  },
                                  '::-webkit-scrollbar-track': {
                                    background: '#f0f0f0',
                                  },
                                }),
                                singleValue: (provided: CSSProperties, { data }) => {
                                  return {
                                    ...provided,
                                    color: data?.value ? 'var(--content-main)' : 'var(--content-dop-light)',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    lineHeight: 'normal',
                                  };
                                },
                                menu: (provided: CSSProperties) => ({
                                  ...provided,
                                  zIndex: 2,
                                  color: 'var(--content-main)',
                                  fontSize: '14px',
                                  fontWeight: 400,
                                  lineHeight: 'normal',
                                }),
                                input: (provided: CSSProperties) => ({
                                  ...provided,
                                  padding: '0 !important',
                                  margin: '0 !important',
                                }),
                                valueContainer: (provided: CSSProperties) => ({
                                  ...provided,
                                  padding: '0 !important',
                                  margin: '0 12px !important',
                                }),
                                indicatorSeparator: (provided: CSSProperties) => ({
                                  ...provided,
                                  display: 'none',
                                }),
                                dropdownIndicator: (provided: CSSProperties) => ({
                                  ...provided,
                                  height: '32px !important',
                                  paddingTop: '5px',
                                }),
                                indicatorsContainer: (provided: CSSProperties) => ({
                                  ...provided,
                                  minHeight: '32px !important',
                                  height: '32px !important',
                                }),
                              }
                            }
          />
        </div>
        <div className={s['choose-date']}>
          <AntLabel required>{t('scannerCourierPage.manual.estimatedDeliveryDate')}</AntLabel>
          <AntDatePicker
            key={`${scanInstance?.instance} ${isConfirmModalOpen}`}
            rootClassName={s['date-picker']}
            onChange={handleChangeDate}
            format={"DD MMMM YYYY"}
            error={error?.date}
            success={estimatedDate && !isConfirmModalOpen && t('scannerCourierPage.manual.estimatedDeliveryDateChosen')}
          />
        </div>
      </section>
      <section className={s['table-section']}>
        {
          scanInstance?.id && (
            <ScanTable
              key={orders}
              isConfirmModalOpen={isConfirmModalOpen}
              instance={scanInstance}
              date={estimatedDate}
              setError={setError}
              tableLoading={tableLoading}
              orders={orders}
              setOrders={setOrders}
              setScanInstance={setScanInstance}
              scanInstance={scanInstance}
              updateAllOrders={updateAllOrders}
            />
          )
        }
      </section>
    </div>
  );
};


export default ScannerView;