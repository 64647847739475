import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ArrowRepeat } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { ResponseError } from 'superagent'

import OrderItemCard from './OrderItemCard'
import useSort from '@/common/hooks/useSort'
import { OrderItem } from '@/common/models/order'
import useSearch from '@/common/hooks/useSearch'
import { useQuery } from 'react-query'
import { OrderApiPageResponse } from '@/common/models/api/v0/order.dto'
import OrderService from '@/telegram/api/OrderService'
import { CourierStatusMode, Statuses } from '@/common/constants/statuses'
import useAlert from '@/common/hooks/useAlert'
import LoadingList from '@/telegram/components/placeholder/LoadingList'
import NoDataList from '@/telegram/components/placeholder/NoDataList'
import { SortDirection } from '@/common/constants/util'
import useCourier from '@/common/hooks/useCourier';
import { observer } from 'mobx-react-lite';
import { courierDeliveryTypeOptions } from '@/common/storage/courierStore';

const OrderItemList = observer(() => {
    const { t } = useTranslation()
    const { searchQuery, searchType, setSearchQuery, setSearchType} = useCourier()

    const { openNotification } = useAlert()
    const { data, isFetching, refetch: refetchOrderItems } = useQuery<OrderApiPageResponse>(['courier-orders'],
        () => OrderService.getAll({ deliveryStatuses: Statuses.courierAppStatusLists[CourierStatusMode.DELIVERY] }),
        { onError: (err: ResponseError) => openNotification('error', err.response?.body?.errorMessage || t('api.messages.serviceDown')) })

    const { sortedData } = useSort<OrderItem>(data?.orders, [{ key: 'createDate', direction: SortDirection.Descending }], () => {})


    const { searchResult } = useSearch<OrderItem>(sortedData, searchType, searchQuery)

    const handleSearchTypeChange = (value: string) => {
        setSearchType(value)
    }

    const handleSearchInput = (value: string) => {
        setSearchQuery(value)
    }

    const handleOrderCardClick = (orderItem: OrderItem) => {
        navigate('/telegram/courier/order-item/view', { state: { orderItemId: orderItem.id } })
    }

    const navigate = useNavigate()

    return isFetching ? (
        <LoadingList />
    ) : (
        <div>
            <div className="py-3">
                <InputGroup className="d-flex">
                    <Form.Select
                        style={{ flex: '1' }}
                        className="border-rounded-1 flex-1"
                        value={searchType}
                        onChange={(e: any) => handleSearchTypeChange(e.target.value)}
                    >
                        {
                            courierDeliveryTypeOptions[CourierStatusMode.DELIVERY]?.map(item => (
                              <option key={item?.value} value={item?.value}>{t(item?.label)}</option>
                            ))
                        }
                    </Form.Select>
                    <Form.Control
                        type={searchType === 'orderItemId' ? 'number' : 'text'}
                        value={searchQuery}
                        style={{ flex: '4' }}
                        className="border-rounded-1 flex-3"
                        placeholder="Введите запрос"
                        onChange={(e: any) => handleSearchInput(e.target.value)}
                    />
                </InputGroup>
            </div>
            <div className="d-flex align-items-center">
                <span className="heading-font">{t('telegram.pages.orderAction.counter.total')} {searchResult.length}</span>
                <span
                    className="ms-auto d-flex align-items-center border border-rounded-1 bg-light p-2"
                    onClick={() => refetchOrderItems()}
                >
                    <ArrowRepeat size="25" />
                </span>
            </div>
            {searchResult.length > 0 ? (
                <div>
                    {searchResult.map((orderItem, index) => (
                        <OrderItemCard
                            key={index}
                            data={orderItem}
                            onClick={() => handleOrderCardClick(orderItem)}
                        />
                    ))}
                </div>
            ) : (
                <NoDataList />
            )}
        </div>
    )
})

export default OrderItemList