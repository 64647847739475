import React, {FC, useState} from 'react'
import { Badge, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Input } from 'antd'
import WebApp from '@twa-dev/sdk'
import { ResponseError } from 'superagent'
import { useNavigate } from 'react-router-dom';

import {convertToTimeZone, getDeliveryPartyCity} from '@/common/utils/utils'
import OrderService from '@/telegram/api/OrderService'
import { Formats } from '@/common/constants/dateFormat'
import { OrderItem } from '@/common/models/order'
import OrderAttachmentList from './OrderItemAttachmentList'
import useAlert from '@/common/hooks/useAlert'
import PhoneLink from '@/telegram/components/PhoneLink'
import OrderProperty from '@/telegram/components/OrderProperty'
import OrderEventActionBar from '@/telegram/components/event-actionbar/OrderEventActionBar'
import { Statuses } from '@/common/constants/statuses'
import AntHeading from '@/components/controls/heading/antHeading'
import actions from '@/telegram/components/event-actionbar/actions';

type OrderItemStatusT = { currentStatus: string, statusComment?: string }

type VariablesT = { orderItemId: number; event: string, comment: string }

const OrderItemActionCard: FC<OrderItemActionCardProps> = ({ data }) => {
    const { t } = useTranslation()
    const { openNotification } = useAlert()
    const { showPopup } = WebApp
    const navigate = useNavigate()

    const orderItemId = data?.id
    const [orderItemStatus, setOrderItemStatus] = useState<OrderItemStatusT>({
        currentStatus: data.status,
        statusComment: null,
    })

    const setCurrentStatus = (currentStatus: string) => 
        setOrderItemStatus((prevValue) => ({ ...prevValue, currentStatus }))

    const setStatusComment = (statusComment: string) =>
        setOrderItemStatus((prevValue) => ({ ...prevValue, statusComment }))

    const { mutate: sendStatusChange, isLoading } = useMutation<string, ResponseError, VariablesT>(
        ({ orderItemId, event, comment }) => OrderService.changeStatus(orderItemId, event, comment), 
        {
            onSuccess: (newStatus) => {
                setCurrentStatus(newStatus)
                setStatusComment(null)
                if(newStatus && !actions[newStatus]){
                    navigate('/telegram/courier')
                }
            },
            onError: (error) => openNotification('error', error?.response?.body?.errorMessage || t('api.messages.serviceDown')),
        })

    const handleActionClick = (event: string) => {
        showPopup(
            {
                message: t('telegram.pages.orderAction.confirmAction', { event: t(`orderEvents.${event}`) }),
                buttons: [
                    { id: event, text: t('telegram.pages.orderAction.buttons.confirmBtn'), type: 'default' },
                    { type: 'cancel' }
                ]
            },
            (value) => value && value !== '' && sendStatusChange({ orderItemId, event: value, comment: orderItemStatus.statusComment })
        )
    }

    return (
        <div>
            {isLoading &&
                <Spinner />}
            <div className="d-flex mb-4">
                <div>
                    <AntHeading size="h3">{t('telegram.pages.orderAction.orderItem')} №{data.id}</AntHeading>
                    <span className="text-muted">
                        {t(`orderStatus.${orderItemStatus.currentStatus}`, {
                            from_city: getDeliveryPartyCity(data.deliverySender),
                            to_city: getDeliveryPartyCity(data.deliveryReceiver),
                        })}
                    </span>
                </div>
            </div>
            <div className="position-relative bg-white border border-rounded-2 p-3">
                <div className="position-absolute" style={{ top: '-12px' }}>
                    <Badge className="border" pill>
                        <span className="ms-auto heading-badge">
                            {convertToTimeZone(data.createDate, false, Formats.DATE_DMY_TIME)}
                        </span>
                    </Badge>
                </div>
                <div>
                    <div className="mb-2">
                        <OrderProperty
                            text={t('telegram.pages.orderAction.deliverySender')}
                            value={data?.deliverySender?.company?.name} 
                        />
                    </div>
                    <div className="my-2">
                        <OrderProperty 
                            text={t('telegram.pages.orderAction.deliverySenderAddress')}
                            value={data?.deliverySender?.address?.addressString}
                        />
                    </div>
                    <div className="my-2">
                        <div>
                            <span className="d-block heading-font">{data?.deliverySender?.contactName}</span>
                            <PhoneLink phone={data?.deliverySender?.phone}/>
                        </div>
                    </div>
                    <div className="my-2">
                        <OrderProperty 
                            text={t('telegram.pages.orderAction.deliveryReceiver')}
                            value={data?.deliveryReceiver?.company?.name} 
                        />
                    </div>
                    <div className="my-2">
                        <OrderProperty 
                            text={t('telegram.pages.orderAction.deliveryReceiverAddress')}
                            value={data?.deliveryReceiver?.address?.addressString} 
                        />
                    </div>
                    <div className="my-2">
                        <div>
                            <span className="d-block heading-font">{data?.deliveryReceiver?.contactName}</span>
                            <PhoneLink phone={data?.deliveryReceiver?.phone}/>
                        </div>
                    </div>
                    <div className="my-2">
                        <OrderProperty 
                            text={t('telegram.pages.orderAction.comments')}
                            value={data?.comments} 
                        />
                    </div>
                </div>
                <div>
                    <span className='d-block heading-font'>{t('telegram.pages.orderAction.report')}</span>
                    <OrderAttachmentList orderItemId={data?.id} />
                </div>
                {Statuses.courierCommentStatus.includes(orderItemStatus.currentStatus) && (
                    <div className="mt-2">
                        <Input.TextArea 
                            onChange={(e) => setStatusComment(e.target.value)}
                            size="large"
                            rows={4} 
                            placeholder={t('telegram.pages.orderAction.commentPlaceholder')} 
                        />
                    </div>
                )}
            </div>

            <div className="mt-4">
                <OrderEventActionBar
                    status={orderItemStatus.currentStatus}
                    onClick={handleActionClick} 
                />
            </div>
        </div>
    )
}

interface OrderItemActionCardProps {
    data: OrderItem,
}

export default OrderItemActionCard