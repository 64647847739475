import { DeliveryProcessType } from "./deliveryProcess"

export enum CourierStatusMode {
	PICKUP = 'pickup',
	DELIVERY = 'delivery',
}

export class Statuses {

	static readonly successFinishedStatuses = [
		'DELIVERED_BY_COURIER', 'DELIVERED_ACCEPTED_MANAGER'
	]

	static readonly printAccessibleStatuses = [
		'MARKED_AT_WAREHOUSE', 'SORTING_AT_WAREHOUSE', 'SORTED_AT_WAREHOUSE',
		'READY_FOR_DELIVERY', 'DELIVERY_COURIER_ASSIGNED', 'DELIVERY_IN_PROGRESS',
		'READY_FOR_DEPARTURE_DELIVERY', 'DELIVERY_TO_DEPARTURE', 'DELIVERY_IN_TRANSIT',
		'READY_FOR_ARRIVAL_DELIVERY', 'PICKUP_COURIER_ARRIVAL_ASSIGNED',
		'DELIVERY_COURIER_ARRIVAL_ASSIGNED', 'DELIVERED_BY_COURIER',
		'DELIVERED_BY_COURIER', 'DELIVERED_ACCEPTED_MANAGER',
	]

	/**
	 * Instant transition rules.
	 */
	static readonly courierAssignableStatuses = {
		'READY_FOR_PICKUP': 'PICKUP_COURIER_ASSIGNED',
		'READY_FOR_DELIVERY': 'DELIVERY_COURIER_ASSIGNED',
		'READY_FOR_DEPARTURE_DELIVERY': 'DELIVERY_TO_DEPARTURE',
		'READY_FOR_ARRIVAL_DELIVERY': 'PICKUP_COURIER_ARRIVAL_ASSIGNED',
	}

	static readonly historyCourierDisplayStatuses = [
		'ON_WAY_TO_WAREHOUSE',
		'DELIVERY_IN_PROGRESS',
		'DELIVERED_BY_COURIER',
		'DELIVERY_TO_DEPARTURE',
		'DELIVERY_COURIER_ARRIVAL_ASSIGNED',
	]

		static readonly deliveryProcessChangeableStatuses = ['NEW', 'READY_FOR_PICKUP', 'PICKUP_COURIER_ASSIGNED', 'ON_WAY_TO_WAREHOUSE', 'DELIVERED_TO_WAREHOUSE', 'ACCEPTED_BY_WAREHOUSE', 'MARKING_AT_WAREHOUSE', 'MARKED_AT_WAREHOUSE', 'SORTING_AT_WAREHOUSE', 'SORTED_AT_WAREHOUSE', 'READY_FOR_DEPARTURE_DELIVERY', 'READY_FOR_DELIVERY']


	static readonly courierScanChangeableStatuses = [
		'READY_FOR_PICKUP',
		'READY_FOR_DELIVERY',
		'READY_FOR_DEPARTURE_DELIVERY',
		'PICKUP_COURIER_ASSIGNED',
		'DELIVERY_COURIER_ASSIGNED',
		'PICKUP_COURIER_ARRIVAL_ASSIGNED',
		'DELIVERY_COURIER_ARRIVAL_ASSIGNED',
		'ON_WAY_TO_WAREHOUSE',
		'DELIVERED_TO_WAREHOUSE',
		'DELIVERY_IN_PROGRESS',
		'DELIVERY_TO_DEPARTURE',
		'DELIVERED_BY_COURIER',
	]


	static readonly courierChangeStatuses = [
		'READY_FOR_PICKUP',
		'READY_FOR_DELIVERY',
		'READY_FOR_DEPARTURE_DELIVERY',
		'READY_FOR_ARRIVAL_DELIVERY',
		'PICKUP_COURIER_ASSIGNED',
		'DELIVERY_COURIER_ASSIGNED',
		'PICKUP_COURIER_ARRIVAL_ASSIGNED',
		'DELIVERY_COURIER_ARRIVAL_ASSIGNED',
		'ON_WAY_TO_WAREHOUSE',
		'DELIVERY_IN_TRANSIT',
		'DELIVERED_TO_WAREHOUSE',
		'DELIVERY_TO_DEPARTURE',
		'DELIVERY_IN_PROGRESS',
		'DELIVERY_TO_DEPARTURE',
		'DELIVERED_BY_COURIER',
	]

	static readonly courierAppStatusLists = {
		[CourierStatusMode.DELIVERY]: 'DELIVERY_COURIER_ASSIGNED,DELIVERY_IN_PROGRESS,DELIVERY_TO_DEPARTURE,DELIVERY_COURIER_ARRIVAL_ASSIGNED',
		[CourierStatusMode.PICKUP]: 'ON_WAY_TO_WAREHOUSE,PICKUP_COURIER_ASSIGNED,PICKUP_COURIER_ARRIVAL_ASSIGNED',
	}

	static readonly courierCommentStatus = [
		'DELIVERY_IN_PROGRESS', 'DELIVERY_COURIER_ARRIVAL_ASSIGNED'
	]

	static readonly thirdPartyAssignableStatuses = [
		'NEW, READY_FOR_PICKUP', 'PICKUP_COURIER_ASSIGNED', 'ON_WAY_TO_WAREHOUSE',
		'DELIVERED_TO_WAREHOUSE', 'ACCEPTED_BY_WAREHOUSE', 'MARKING_AT_WAREHOUSE', 'MARKED_AT_WAREHOUSE',
		'SORTING_AT_WAREHOUSE', 'SORTED_AT_WAREHOUSE', 'READY_FOR_DELIVERY', 'READY_FOR_DEPARTURE_DELIVERY'
	]

	static readonly adminInCityStatus = [
		'NEW', 'READY_FOR_PICKUP', 'PICKUP_COURIER_ASSIGNED', 'ON_WAY_TO_WAREHOUSE', 'DELIVERED_TO_WAREHOUSE',
		'PENDING_CLIENT_CLARIFICATION', 'DELIVERY_DESTROYED', 'DELIVERY_RETURNED_TO_SENDER', 'ACCEPTED_BY_WAREHOUSE',
		'MARKING_AT_WAREHOUSE', 'MARKED_AT_WAREHOUSE', 'SORTING_AT_WAREHOUSE', 'SORTED_AT_WAREHOUSE', 'READY_FOR_DELIVERY',
		'DELIVERY_COURIER_ASSIGNED', 'DELIVERY_IN_PROGRESS', 'DELIVERED_BY_COURIER', 'DELIVERED_ACCEPTED_MANAGER'
	]

	static readonly adminInterCityStatus = [
		'NEW', 'READY_FOR_PICKUP', 'PICKUP_COURIER_ASSIGNED', 'ON_WAY_TO_WAREHOUSE', 'DELIVERED_TO_WAREHOUSE',
		'PENDING_CLIENT_CLARIFICATION', 'DELIVERY_RETURNED_TO_SENDER', 'ACCEPTED_BY_WAREHOUSE',
		'MARKING_AT_WAREHOUSE', 'MARKED_AT_WAREHOUSE', 'SORTING_AT_WAREHOUSE', 'SORTED_AT_WAREHOUSE', 'READY_FOR_DEPARTURE_DELIVERY',
		'DELIVERY_TO_DEPARTURE', 'DELIVERY_IN_TRANSIT', 'READY_FOR_ARRIVAL_DELIVERY', 'PICKUP_COURIER_ARRIVAL_ASSIGNED',
		'DELIVERY_COURIER_ARRIVAL_ASSIGNED', 'DELIVERED_BY_COURIER', 'DELIVERED_ACCEPTED_MANAGER'
	]

	static readonly adminAlsecoStatus = [
		'PENDING_CLIENT_CLARIFICATION', 'SORTING_AT_WAREHOUSE', 'SORTED_AT_WAREHOUSE', 'READY_FOR_DELIVERY',
		'DELIVERY_COURIER_ASSIGNED', 'DELIVERY_IN_PROGRESS', 'DELIVERED_BY_COURIER', 'DELIVERED_ACCEPTED_MANAGER'
	]

	static readonly adminThirdPartyProviderStatus = [
		'NEW', 'READY_FOR_PICKUP', 'PICKUP_COURIER_ASSIGNED', 'ON_WAY_TO_WAREHOUSE', 'DELIVERED_TO_WAREHOUSE',
		'ACCEPTED_BY_WAREHOUSE', 'MARKING_AT_WAREHOUSE', 'MARKED_AT_WAREHOUSE', 'SORTING_AT_WAREHOUSE',
		'SORTED_AT_WAREHOUSE', 'READY_FOR_DELIVERY', 'DELIVERY_IN_PROGRESS', 'DELIVERED_ACCEPTED_MANAGER'
	]

	static readonly adminStatusMap = {
		[DeliveryProcessType.IN_CITY]: Statuses.adminInCityStatus,
		[DeliveryProcessType.INTER_CITY]: Statuses.adminInterCityStatus,
		[DeliveryProcessType.ALSECO]: Statuses.adminAlsecoStatus,
		[DeliveryProcessType.THIRD_PARTY_PROVIDER]: Statuses.adminThirdPartyProviderStatus,
	}
}