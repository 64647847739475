export const scannerThirdPartyPage = {
  header: "Назначение доставки на Казпочту",
  scanQRTitle: "Отсканируйте QR заказов",
  emptyTable: 'Отсканируйте QR заказов, которые хотите назначить на доставку Казпочтой',
  steps: {
    first: 'Шаг 1: Нажмите на иконку сканера или в любое место на странице, чтобы активировать сканирование',
    second: 'Шаг 2: Отсканируйте QR заказов, которые хотите отправить через Казпочту',
    third: 'Шаг 3: Для подтверждения нажмите кнопку “Подтвердить”',
  },
  errors: {
    badQR: {
      header: 'Ошибка считывания QR-кода',
      text: 'Убедитесь, что QR-код правильный и повторите попытку, также вы можете вручную поменять тип доставки через таблицу Заказов',
    },
    noDateField: 'Не выбрана дата',
    noDateMessage: {
      header: 'Дата ожидаемой доставки не выбрана',
      text: 'Пожалуйста, укажите дату и подтвердите еще раз',
    },
    noFocus: {
      header: 'Вы потеряли фокус страницы',
      text: 'Нажмите в любое место на этой странице, чтобы снова активировать сканирование',
    },
    noOrdersFound: {
      header: 'Заказ с номером {{id}} не найден в системе',
      text: 'Проверьте правильность QR-кода или обратитесь в службу поддержки',
    },
    badOrderStatus: {
      header: 'Доставка через Казпочту недоступна для заказа №{{id}}',
      text: "Убедитесь, что статус и тип заказа подходят, затем попробуйте снова",
    },
    badResponse: {
      header: 'Ошибка сервера',
      text: 'Не удалось назначить тип процесса: Казпочта'
    },
  },
  info: {
    orderExists: {
      header: 'Заказ №{{id}} уже добавлен',
      text: 'Заказ уже есть в списке',
    }
  },
  success: {
    addOrder: {
      header: 'Заказ №{{id}} добавлен',
      text: 'В список назначения заказов на Казпочту',
    },
    assignProcessType: {
      header: 'Заказы успешно назначены на доставку Казпочтой',
      text: 'Назначенные заказы можно найти в таблице Заказов, применив фильтр - Тип доставки: Казпочта',
    },
    deleteOrder: {
      header: 'Заказ №{{id}} успешно удален',
      text: 'Вы можете отсканировать его QR заново',
    },
  },
  confirm: {
    deleteHeader: 'Вы уверены, что хотите удалить заказ?',
    assignHeader: 'Вы уверены, что хотите подтвердить?',
    text: 'Это действие нельзя отменить ',
    ok: 'Да, подтвердить',
    no: 'Отменить',
    button: 'Подтвердить',
  },
  modal: {
    header: 'Назначены заказы на доставку Казпочтой',
    body: {
      processType: 'Тип доставки: ',
      assigned: {
        start: 'Назначено: ',
        end: '{{length}} заказов'
      },
    },
  }
}