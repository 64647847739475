import React, { useEffect } from "react";
import useAlert from '@/common/hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { findInstanceProps, QrType } from '@/common/models/qr';
import { useMutation } from 'react-query';
import OrderService from '@/common/api/OrderService';
import { OrderItem } from '@/common/models/order';
import { standardizeScannedData } from '@/common/utils/scanUtils';
import { CellService } from '@/common/api/CellService';

const ScannerHandlerComponent = ({scanInstance, setScanInstance, orders, setOrders, setError, updateAllOrders}) => {

  const { t } = useTranslation()
  const { openNotification } = useAlert()

  const { mutate: fetchOrder } = useMutation(['add-scanned-order'],
    (id) => OrderService.getById(id),
    {
      onSuccess: (data: OrderItem) => {
        setOrders(prev => [...prev, data])
        openNotification('success', t('scannerCellPage.success.addOrder.header', {id: data?.id}), t('scannerCellPage.success.addOrder.text'))
      },
      onError: (_, id) => {
        openNotification('error', t('scannerCellPage.errors.noOrdersFound.header', {id: id}), t('scannerCellPage.errors.noOrdersFound.text'))
      }
    },
  );

  const { mutateAsync: findInstance } = useMutation(['get-scan-instance'], ({ type, id }: findInstanceProps) => {
    setScanInstance({type: type, id: id, instance: null})
    if(type === QrType.CELL) {
      return CellService.getById(id)
    }
  }, {
    onSuccess: (data) => {
      setScanInstance(prev => ({
        ...prev,
        instance: data
      }))
    },
    onError: (error) => {
      openNotification('error', t('api.messages.error'), error?.response?.body?.errorMessage || t('api.messages.serviceDown'))
    }
  })
  useEffect(() => {
    let buffer = "";
    let timeout;

    const processScannedData = async (data) => {
      const isOrderItem = data?.type === QrType.ORDER_ITEM || (Object.keys(data).includes('orderId') && Object.keys(data).includes('id'))
      if(isOrderItem && !(scanInstance?.type || scanInstance?.id)) {
        setError(t('scannerCellPage.errors.noCell'))
      } else {
        setError('')
        if(data?.type === QrType.CELL) {
          if (!scanInstance?.id) {
            await findInstance({type: data?.type, id: data?.id})
          } else if(scanInstance?.id === data?.id) {
            if(orders?.length < 1) {
              openNotification('error', t('scannerCellPage.errors.noOrders.header'), t('scannerCellPage.errors.noOrders.text'), 0)
            } else {
              updateAllOrders()
            }
          } else {
            openNotification('error', t('scannerCellPage.errors.cellNotMatched.header'), t('scannerCellPage.errors.cellNotMatched.text'), 0)
          }
        }
        if(isOrderItem) {
          if(orders?.find(order => order?.id === data?.id)) {
            openNotification('warning', t('scannerCellPage.info.orderExists.header', {id: data?.id}), t('scannerCellPage.info.orderExists.text'))
          }
          else {
            fetchOrder(data?.id)
          }
        }
      }
    };
    const handleKeyPress = (event) => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        buffer = "";
      }, 300); // Timeout for next scan

      if (event.key === "Enter") {
        const standardizedData = standardizeScannedData(buffer);
        if(standardizedData && standardizedData?.id) {
          processScannedData(standardizedData);
        } else {
          openNotification('error', t('scannerCellPage.errors.badQR.header'), t('scannerCellPage.errors.badQR.text'), 0)
        }
        buffer = "";
      } else {
        buffer += event.key;
      }
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
      clearTimeout(timeout);
    };
  }, [updateAllOrders, fetchOrder, openNotification, orders, scanInstance, scanInstance?.id, scanInstance?.type, setError, setScanInstance, t, findInstance]);



  return (
    <></>
  );
};

export default ScannerHandlerComponent;
