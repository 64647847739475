import React, { FC, useEffect } from 'react';
import useAlert from '@/common/hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { QrType } from '@/common/models/qr';
import { useMutation } from 'react-query';
import OrderService from '@/common/api/OrderService';
import { OrderItem } from '@/common/models/order';
import { standardizeScannedData } from '@/common/utils/scanUtils';
import { Statuses } from '@/common/constants/statuses';
import { getDeliveryPartyCity } from '@/common/utils/utils';

const ScannerHandlerComponent: FC<ScannerHandlerProps> = ({orders, setOrders}) => {

  const { t } = useTranslation()
  const { openNotification } = useAlert()

  const allowedStatuses = Statuses.thirdPartyAssignableStatuses

  const { mutate: fetchOrder } = useMutation(['add-scanned-order'],
    (id) => OrderService.getById(id),
    {
      onSuccess: (data: OrderItem) => {
        if(allowedStatuses.includes(data?.status)) {
          setOrders((prev: OrderItem[]) => [...prev, data])
          openNotification('success', t('scannerThirdPartyPage.success.addOrder.header', {id: data?.id}), t('scannerThirdPartyPage.success.addOrder.text'))
        } else {
          openNotification('error', t('scannerThirdPartyPage.errors.badOrderStatus.header', {id: data?.id, status: t(`orderStatus.${data?.status}`, {from_city: getDeliveryPartyCity(data?.deliverySender), to_city: getDeliveryPartyCity(data?.deliveryReceiver)})}), t('scannerThirdPartyPage.errors.badOrderStatus.text'), 0)
        }
      },
      onError: (_, id) => {
        openNotification('error', t('scannerThirdPartyPage.errors.noOrdersFound.header', {id: id}), t('scannerThirdPartyPage.errors.noOrdersFound.text'))
      }
    },
  );

  useEffect(() => {
    let buffer = "";
    let timeout;

    const processScannedData = async (data) => {
      const isOrderItem = data?.type === QrType.ORDER_ITEM || (Object.keys(data).includes('orderId') && Object.keys(data).includes('id'))
      if(isOrderItem) {
        if(orders?.find(order => order?.id === data?.id)) {
          openNotification('warning', t('scannerThirdPartyPage.info.orderExists.header', {id: data?.id}), t('scannerThirdPartyPage.info.orderExists.text'))
        }
        else {
          fetchOrder(data?.id)
        }
      }
    };
    const handleKeyPress = (event) => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        buffer = "";
      }, 300); // Timeout for next scan

      if (event.key === "Enter") {
        const standardizedData = standardizeScannedData(buffer);
        if(standardizedData && standardizedData?.id) {
          processScannedData(standardizedData);
        } else {
          openNotification('error', t('scannerThirdPartyPage.errors.badQR.header'), t('scannerThirdPartyPage.errors.badQR.text'), 0)
        }
        buffer = "";
      } else {
        buffer += event.key;
      }
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
      clearTimeout(timeout);
    };
  }, [fetchOrder, openNotification, orders, t]);



  return (
    <></>
  );
};

interface ScannerHandlerProps {
  orders: OrderItem[],
  setOrders: (orders: OrderItem[]) => void,
}

export default ScannerHandlerComponent;
