import React, { FC, useCallback, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next'
import { Button, GetProp, Image, Upload, UploadFile, UploadProps } from 'antd';
import { ResponseError } from 'superagent'
import WebApp from '@twa-dev/sdk'

import { AttachmentResponse } from '@/common/models/api/v0/attachment.dto'
import OrderService from '@/telegram/api/OrderService'
import CameraCapture from './CameraCapture'
import useAlert from '@/common/hooks/useAlert'
import AntButton from '@/components/buttons/antButton';
import { DeleteOutlined } from '@ant-design/icons';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

type UploadT = {
    error?: string,
    isUploading: boolean,
    files: UploadFile[],
}

type PreviewT = {
    isOpen: boolean,
    imageBase64: string,
}

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = (error) => reject(error)
    })

const OrderAttachmentList: FC<OrderAttachmentsProps> = ({ orderItemId }) => {
  const { t } = useTranslation()
  const { openNotification } = useAlert()
  const { showPopup } = WebApp
  const UPLOAD_LIMIT = 30


  const uploadRef = useRef(null);
  const { data: attachments, refetch: refetchAttachments, isFetching: isFetchingAttachments } = useQuery<AttachmentResponse[]>(['order-attachments'],
        () => OrderService.getAllFiles(orderItemId),
        { onError: (error) => handleApiError(error as ResponseError) })

  const handleApiError = useCallback((error: ResponseError) => {
    openNotification('error', error.response?.body?.errorMessage || t('api.messages.serviceDown'))
  }, [openNotification, t]);


  const { mutate: uploadFiles } = useMutation<void, ResponseError, {orderItemId: number, files: string[]}>(
    ({ orderItemId, files }) => {
      setIsUploading(true)
      return OrderService.saveAllFiles(orderItemId, files)
    }, {
    onSuccess: () => {
      openNotification('success', t('telegram.pages.orderAction.progressLabels.loaded'))
      refetchAttachments();
    },
    onError: (error: ResponseError) => handleApiError(error as ResponseError),
    onSettled: () => setIsUploading(false)
  })

    const [upload, setUpload] = useState<UploadT>({
        files: [],
        isUploading: false,
    })
    const [preview, setPreview] = useState<PreviewT>({
        imageBase64: '',
        isOpen: false,
    })

  const [isCameraOpen, setIsCameraOpen] = useState(false);

    const setIsUploading = (isUploading: boolean) =>
        setUpload(prevState => ({ ...prevState, isUploading }))

    const setFiles = (files: UploadFile[]) =>
        setUpload(prevState => ({ ...prevState, files }))

    const setPreviewImage = (imageBase64: string) =>
        setPreview(prevState => ({ ...prevState, imageBase64 }))

    const setPreviewIsOpen = (isOpen: boolean) =>
        setPreview(prevState => ({ ...prevState, isOpen }))

  const handleUpload = async (file) => {
    setIsUploading(true)
    const newFileString = await getBase64(file);
    uploadFiles({ orderItemId, files: [newFileString] })
    setFiles([]);
  };


    const handlePreview = async (file) => {
      setPreviewImage(file.file);
      setPreviewIsOpen(true);
    }


    const handleUploadRemove: UploadProps['onRemove'] = (file) => {
        showPopup(
            {
                title: t('telegram.pages.orderAction.confirmRemoveTitle', { name: file.name }),
                message: t('telegram.pages.orderAction.confirmRemove'),
                buttons: [
                    { id: 'yes', text: t('telegram.pages.orderAction.buttons.confirmBtn'), type: 'destructive' },
                    { type: 'cancel' }
                ]
            },
            (value) => value === 'yes' && handlePopupRemoveAction(file)
        )
    }

    const handlePopupRemoveAction = (file: UploadFile) => OrderService.deleteFile(orderItemId, Number(file.id))
        .then(() => refetchAttachments())
        .catch((e) => handleApiError(e))

    const handleCameraClose = (files: string[]) => {
      setIsCameraOpen(false)
      if(files?.length > 0){
        uploadFiles({ orderItemId, files: files })
      }
    }
    const triggerUpload = () => {
      const input = uploadRef.current?.nativeElement.querySelector('input[type="file"]');
      if (input) {
        input.click();
      }
    };

    return isFetchingAttachments ? (
        <div className="d-flex justify-content-center border-dotted border-rounded-2 p-3">
            <Spinner />
        </div>
    ) : (
      <div>
        {
          isCameraOpen && (
            <CameraCapture
              onClose={handleCameraClose}
              allowedSize={UPLOAD_LIMIT - attachments?.length}
            />
          )
        }

        <div className="py-2">
          <span className="heading-font">
              {t('telegram.pages.orderAction.uploadedCount')} {attachments?.length}/{UPLOAD_LIMIT}
          </span>
        </div>
          <div>
            {
              attachments?.length < UPLOAD_LIMIT && (
                <div className='d-flex justify-content-between py-2'>
                  <Button
                    size='large'
                    type={'primary'}
                    style={{ padding: '22.25px 50px', borderRadius: '4px' }}
                    onClick={() => setIsCameraOpen(!isCameraOpen)}
                  >
                    {t('telegram.pages.orderAction.buttons.openCameraBtn')}
                  </Button>
                  <AntButton type={'default'}
                             size={'small'}
                             disabled={upload.isUploading}
                             onClick={() => triggerUpload()}
                             styles={{
                               border: '2px solid var(--accent-main)',
                               borderRadius: '4px',
                             }}
                  >
                    {t('telegram.pages.orderAction.buttons.uploadBtn')}
                  </AntButton>
                </div>
              )
            }

            <Upload
              ref={uploadRef}
              listType='picture-card'
              accept='image/*'
              beforeUpload={handleUpload}
              openFileDialogOnClick={false}
              showUploadList={false}
            />
            <div className='d-flex flex-row' style={{ overflow: 'scroll', width: '100%' }}>
              {attachments.map((file) => (
                <div
                  key={file.id}
                  style={{
                    width: 'auto',
                    minWidth: '150px',
                    height: '150px',
                    position: 'relative',
                    border: '1px solid var(--content-dop-light)',
                    borderRadius: '10px',
                    margin: '0 10px',
                    padding: '5px',
                  }}
                >
                  <Image
                    src={file.file}
                    preview={false}
                    width="90%"
                    height="95%"
                    style={{
                      objectFit: 'contain',
                      borderRadius: '6px',
                    }}
                    onClick={() => handlePreview(file)}
                  />
                  <DeleteOutlined
                    style={{position: 'absolute', color: 'var(--accent-red)', top: 0, bottom: 0, right: 0, cursor: 'pointer'}}
                    onClick={() => handleUploadRemove(file)}
                  />
                </div>
              ))}
            </div>
          </div>
        {preview.imageBase64 && (
          <Image
            wrapperStyle={{ display: 'none' }}
            crossOrigin='anonymous'
            preview={{
              visible: preview.isOpen,
              onVisibleChange: (visible) => setPreviewIsOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(''),
            }}
            src={preview.imageBase64}
          />
        )}
      </div>
    )
}

interface OrderAttachmentsProps {
  orderItemId: number,
}

export default OrderAttachmentList