export const ruScannerCourierPage = {
  header: "Назначение курьера",
  scanQRTitle: "Отсканируйте QR курьеров и заказов",
  emptyTable: 'Отсканируйте QR заказов, которые хотите назначить на выбранного курьера',
  steps: {
    first: 'Шаг 1: Нажмите на иконку сканера или в любое место на странице, чтобы активировать сканирование',
    second: 'Шаг 2: Отсканируйте QR-код курьера или выберите курьера вручную из списка, затем укажите дату ожидаемой доставки',
    third: 'Шаг 3: Отсканируйте QR заказов, которые хотите назначить на выбранного курьера',
    fourth: 'Шаг 4: Для подтверждения отсканируйте повторно QR курьера или нажмите кнопку “Подтвердить”',
  },
  errors: {
    badQR: {
      header: 'Этот QR-код не распознан',
      text: 'Попробуйте снова или обратитесь в службу поддержки. Также вы можете вручную назначить курьера через таблицу Заказов',
    },
    noOrders: {
      header: 'Вы не добавили заказы',
      text: 'Сначала отсканируйте хотя бы один заказ, чтобы назначить курьера',
    },
    noCourier: 'Курьер не выбран, отсканируйте QR курьера или выбрите из списка',
    noDateField: 'Не выбрана дата',
    noDateMessage: {
      header: 'Дата ожидаемой доставки не выбрана',
      text: 'Пожалуйста, укажите дату и подтвердите еще раз',
    },
    noFocus: {
      header: 'Вы потеряли фокус страницы',
      text: 'Нажмите в любое место на этой странице, чтобы снова активировать сканирование',
    },
    noOrdersFound: {
      header: 'Заказ с номером {{id}} не найден в системе',
      text: 'Проверьте правильность QR-кода или обратитесь в службу поддержки',
    },
    badOrderStatus: {
      header: 'Заказ №{{id}} нельзя назначить курьеру, так как его текущий статус: {{status}}',
      text: "Назначение возможно только для заказов с подходящим статусом.",
    },
    courierNotMatched: {
      header: 'QR курьера не совпадает с выбранным курьером',
      text: 'Убедитесь, что QR-код принадлежит тому же курьеру, которого вы выбрали ранее',
    },
    badResponse: {
      header: 'Ошибка сервера',
      text: 'Не удалось назначить курьера и сменить дату ожидаемой доставки'
    },
  },
  info: {
    orderExists: {
      header: 'Заказ №{{id}} уже добавлен',
      text: 'Заказ уже есть в списке',
    }
  },
  success: {
    addOrder: {
      header: 'Заказ №{{id}} добавлен',
      text: 'В список назначения заказов на курьера',
    },
    assignCourier: {
      header: 'Заказы успешно назначены на курьера {{fullName}}',
      text: 'Назначенные заказы можно найти в таблице Заказов, применив фильтр по выбранному курьеру',
    },
    deleteOrder: {
      header: 'Заказ №{{id}} успешно удален',
      text: 'Вы можете отсканировать его QR заново',
    }
  },
  manual: {
    courierChosen: 'Курьер успешно выбран',
    courier: 'Курьер',
    estimatedDeliveryDate: 'Дата ожидаемой доставки',
    estimatedDeliveryDateChosen: 'Дата ожидаемой доставки успешно выбрана',
  },
  confirm: {
    deleteHeader: 'Вы уверены, что хотите удалить заказ?',
    assignHeader: 'Вы уверены, что хотите подтвердить?',
    text: 'Это действие нельзя отменить ',
    ok: 'Да, подтвердить',
    no: 'Отменить',
    button: 'Подтвердить',
  },
  modal: {
    header: 'Назначены заказы на курьера {{fullName}}',
    body: {
      courier: 'Курьер: ',
      assigned: {
        start: 'Назначено: ',
        end: '{{length}} заказов'
      },
      date: 'Ожидаемая дата доставки: ',
    },
    printCouriers: 'Распечатать реестр',
  }
}