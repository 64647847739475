import React, { FC, useRef, useState } from 'react';
import { Camera } from 'react-camera-pro'
import { Image } from 'antd'
import { useTranslation } from 'react-i18next'
import WebApp from '@twa-dev/sdk'

const CameraCapture: FC<CameraCaptureProps> = ({ onClose, allowedSize }) => {
    const { t } = useTranslation()
    const { HapticFeedback } = WebApp

    const camera = useRef(null)
    const [previewFiles, setPreviewFiles] = useState<string[]>([])
    const [isFlashEnabled, setIsFlashEnabled] = useState(false)



    const handleCameraShot = async () => {
        const image = await takePhotoAsync()
        setPreviewFiles([...previewFiles, image])
    }

    const takePhotoAsync = async () => {
        if (isFlashEnabled) {
            camera.current.toggleTorch()
            await new Promise((resolve) => setTimeout(resolve, 1000))
        }

        const fileBase64: string = await camera.current.takePhoto()
        HapticFeedback.notificationOccurred('success');

        if (isFlashEnabled) {
            await new Promise((resolve) => setTimeout(resolve, 200));
            camera.current.toggleTorch();
        }

        return fileBase64
    }

    const handleCameraClose = () => {
        onClose(previewFiles)
        setPreviewFiles([])
    }
    if(allowedSize - previewFiles?.length === 0) {
        handleCameraClose()
    }

    return (
        <div className="camera-container">
            <div className="camera-top-bar">
                <span
                    className={`flash-btn ${isFlashEnabled && 'active'}`}
                    onClick={() => setIsFlashEnabled(!isFlashEnabled)}
                >
                    {t('telegram.pages.orderAction.buttons.flashToggleBtn')}
                </span>
            </div>
            <div>
                <Camera
                    facingMode="environment" 
                    ref={camera}
                    errorMessages={{}}
                />
            </div>
            <div className="camera-preview-bar">
                {previewFiles.map((item, index) => (
                    <div className="px-2">
                        <Image
                            className="border"
                            key={index}
                            width={48}
                            src={item}
                        />
                    </div>
                ))}
            </div>
            <div className="camera-control-bar">
                <button 
                    className="camera-shoot-btn" 
                    onClick={() => handleCameraShot()}
                />
                <span
                    className="camera-close-btn"
                    onClick={handleCameraClose}
                >
                    {
                        previewFiles?.length > 0 ? (
                          t('telegram.pages.orderAction.buttons.saveCameraBtn')
                        ) : (
                          t('telegram.pages.orderAction.buttons.closeCameraBtn')
                        )
                    }
                </span>
            </div>
        </div>
    )
}

interface CameraCaptureProps {
    onClose: (files: string[]) => void,
    allowedSize: number
}

export default CameraCapture