import React, { FC, useState } from 'react';
import * as XLSX from 'xlsx'
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import { format } from 'date-fns';
import { ExportOutlined } from '@ant-design/icons';


import { OrderItem, OrderSearchFilter } from '@/common/models/order';
import {
  appendString,
  buildDeliveryPartyString,
  convertToTimeZone,
  getDeliveryPartyCity,
} from '@/common/utils/utils';
import { Formats } from '@/common/constants/dateFormat';
import OrderService from '@/common/api/OrderService';
import { OrderApiPageResponse } from '@/common/models/api/v0/order.dto';
import useAlert from '@/common/hooks/useAlert';
import { Statuses } from '@/common/constants/statuses'

const {Text} = Typography

interface OrderExportXlsxProps {
  filter: OrderSearchFilter,
  isClient: boolean,
}
const OrderExportXlsx: FC<OrderExportXlsxProps> = ({ filter, isClient }) => {
  const { openNotification  } = useAlert()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const handleExport = async () => {
    try {
      setIsLoading(true)
      const orderData: OrderApiPageResponse = await OrderService.getAll({
        ...filter,
        page: 0,
        size: 5000
      })
      if(orderData?.orders && orderData?.orders?.length < 1){
        openNotification('error', `${t('api.messages.error')}`, `${t('orderExportPage.noDataError')}`)
        return
      }
      const data = isClient ? (
        [
          ['№', t('ordersPage.table.created'), t('ordersPage.table.receiver'), t('ordersPage.table.parcelType'), t('ordersPage.table.deliveryType'), t('ordersPage.table.estimatedDeliveryDate'), t('ordersPage.table.status')],
          ...orderData?.orders?.map((order: OrderItem) => [
            order.id,
            convertToTimeZone(order?.createDate, false, Formats.DATE_DMY_TIME),
            buildDeliveryPartyString(order?.deliveryReceiver),
            t(`parcelType.${order.parcelType}`),
            t(`deliveryType.${order.deliveryType}`),
            order.estimatedDeliveryDate && convertToTimeZone(order?.estimatedDeliveryDate, false, Formats.DATE_DMY_TIME),
            t(`orderStatus.${order.status}`, {
              from_city: getDeliveryPartyCity(order.deliverySender),
              to_city: getDeliveryPartyCity(order.deliveryReceiver),
            }),
          ]),
        ]
      ) : (
        [
          ['№', t('ordersPage.table.created'), t('ordersPage.table.orderNumber'), t('ordersPage.table.sender'),
            t('ordersPage.table.senderInfo'), t('ordersPage.table.receiver'), t('ordersPage.table.receiverInfo'),
            t('ordersPage.table.courier'), t('ordersPage.table.route'), t('ordersPage.table.cell'),
            t('ordersPage.table.deliveryDate'), t('ordersPage.table.details'), t('ordersPage.table.status')],
          ...orderData?.orders?.map((order: OrderItem) => [
            order.id,
            convertToTimeZone(order?.createDate, false, Formats.DATE_DMY_TIME),
            order.orderNumber,
            buildDeliveryPartyString(order?.deliverySender),
            appendString(order?.deliverySender?.contactName, order?.deliverySender?.phone),
            buildDeliveryPartyString(order?.deliveryReceiver),
            appendString(order?.deliveryReceiver?.contactName, order?.deliveryReceiver?.phone),
            Statuses.successFinishedStatuses.includes(order?.status)
              ? appendString(order?.courierDelivered?.lastName, order?.courierDelivered?.firstName)
              : appendString(order?.courier?.lastName, order?.courier?.firstName),
            order.route,
            order.cell,
            [
              order?.actualDeliveryDate && convertToTimeZone(order?.actualDeliveryDate, false, Formats.DATE_DMMMY_TIME, true) + " / ",
              order?.estimatedDeliveryDate && t('ordersPage.table.estimatedDeliveryDate') + " - ",
              convertToTimeZone(order?.estimatedDeliveryDate, false, Formats.DATE_DMMMY_TIME, true)
            ].join('\n'),
            order.comments,
            t(`orderStatus.${order.status}`, {
              from_city: getDeliveryPartyCity(order.deliverySender),
              to_city: getDeliveryPartyCity(order.deliveryReceiver),
            }),
          ]),
        ]
      );
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);

      XLSX.utils.book_append_sheet(wb, ws, t('orderExportPage.sheet'));

      const exportFile = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

      const url = URL.createObjectURL(new Blob([exportFile], { type: 'application/octet-stream' }));
      const now = new Date();
      const a = document.createElement('a');
      a.href = url;
      a.download = t('orderExportPage.fileName',
        {export_date: format(now, Formats.EXPORT_TIMESTAMP)}) // File name
      a.click();

      URL.revokeObjectURL(url);
      a.remove()

    } catch (e) {
      openNotification('error', `${t('api.messages.error')}`, `${t('orderExportPage.serverError')}`)
    } finally {
      setIsLoading(false)
    }

  }
  return (
    <div>
      <Button loading={isLoading} size={'large'} type="primary" onClick={async () => {
        await handleExport()
      }}>
        <Text
          strong
          style={{ color: 'white', fontSize: '0.8rem', margin: 0}}
        >{t('ordersPage.buttons.exportOrders')}</Text>
        <ExportOutlined style={{fontSize: '20px'}}/></Button>
    </div>
  );
}

export default OrderExportXlsx;