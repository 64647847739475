import { replaceCyrillicWithEnglish } from '@/common/utils/qrUtils';
import { RegexPatterns } from '@/common/constants/regexPatterns';

export const standardizeScannedData = (data) => {
  // Step 1: Replace Russian with English counterparts
  const replacedData = replaceCyrillicWithEnglish(data);

  // Step 2: Remove invalid characters to make it JSON-parsable
  const sanitizedData = replacedData.replace(RegexPatterns.redundantCharacters, ''); // Only allow valid JSON characters

  // Step 3: Parsing JSON
  try {
    return JSON.parse(sanitizedData);
  } catch (error) {
    console.error("Error parsing replaced data:", error);
    return null;
  }
};