import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'antd';

import ScanHandler from '@/pages/scanner-cell/components/ScanHandler';
import AntHeading from '@/components/controls/heading/antHeading';
import {ReactComponent as ScanLogo} from '@/assets/icons/scanner/scan.svg'

import s from './index.module.scss'
import AntText from '@/components/controls/text/antText';
import { QrType } from '@/common/models/qr';
import AntLabel from '@/components/controls/antLabel/antLabel';
import ScanTable from '@/pages/scanner-cell/components/ScanTable';
import { usePageFocusTracker } from '@/common/hooks/usePageFocusTracker';
import useAlert from '@/common/hooks/useAlert';
import { useMutation } from 'react-query';
import { OrderItem } from '@/common/models/order';
import OrderService from '@/common/api/OrderService';
import ScanConfirmModal from '@/pages/scanner-cell/components/ScanConfirmModal';
import CellAsyncSelect from '@/components/controls/select/CellAsyncSelect';
import { scanInstance, SelectOption } from '@/common/models/util';
import { orderStatusMap } from '@/components/controls/order-action/actions';


const ScannerView = () => {
  const { t } = useTranslation()
  const isPageFocused = usePageFocusTracker();
  const { openNotification, destroyNotification } = useAlert()

  const {mutateAsync: assignCell} = useMutation(async (order: OrderItem) => {
    await OrderService.updateOrderItem({ cellId: scanInstance?.id}, order?.id)
    if(order?.status === 'SORTING_AT_WAREHOUSE') {
      await OrderService.changeStatus(order?.id, orderStatusMap[order?.status]?.events.find(event => event?.processTypes.includes(order?.deliveryProcessType))?.event)
    }
  });
  const [orders, setOrders] = useState([]);
  const [scanInstance, setScanInstance] = useState<scanInstance>(null);
  const [error, setError] = useState({date: '', scan: ''});

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const clearPage = () => {
    setScanInstance(null)
    setOrders([])
  }

  const updateAllOrders = async () => {
    try {
      setTableLoading(true)
      await Promise.all(
        orders.map(async (order) => {
            await assignCell(order);
        })
      );
      setIsConfirmModalOpen(true)
    } catch (e) {
      clearPage()
      openNotification('error', t('scannerCellPage.errors.badResponse.header'), t('scannerCellPage.errors.badResponse.text'), 0)
    } finally {
      setTableLoading(false)
    }
  };

  const handleSelectCell = (selectedOption: SelectOption) => {
    setScanInstance({
      id: selectedOption?.value,
      type: QrType.CELL,
      instance: {
        id: selectedOption?.value,
        name: selectedOption?.label,
        description: null
      },
    });
    setError(prev => ({
      ...prev,
      scan: ''
    }));
  };

  useEffect(() => {
    if(!isPageFocused && !isConfirmModalOpen) {
      openNotification('error', t('scannerCellPage.errors.noFocus.header'), t('scannerCellPage.errors.noFocus.text'), 0, 'noFocus')
    }
    if(isPageFocused) {
      destroyNotification('noFocus')
    }
  }, [destroyNotification, isConfirmModalOpen, isPageFocused, openNotification, t])

  return (
    <div id={s['scan-page']}>
      <ScanConfirmModal key={`${orders?.length} ${scanInstance?.id}`} cell={scanInstance?.instance} orders={orders} isConfirmModalOpen={isConfirmModalOpen} setIsConfirmModalOpen={setIsConfirmModalOpen} clearPage={clearPage}/>
      <ScanHandler updateAllOrders={updateAllOrders} scanInstance={scanInstance} setScanInstance={setScanInstance} orders={orders} setOrders={setOrders} setError={(errText: string) => {
        setError(prev => ({
          ...prev,
          scan: errText,
        }))
      }}/>
      <AntHeading size={'h2'} style={{marginTop: '25px'}}>{t('scannerCellPage.header')}</AntHeading>
      <AntHeading size={'h3'} style={{marginTop: '34px', marginBottom: '16px', lineHeight: '17px'}}>{t('scannerCellPage.scanQRTitle')}</AntHeading>
      <section className={s['qr-section']}>
        <ScanLogo key={isPageFocused} style={{ color: isPageFocused ? '#7B57DF' : '#3d3d3d', cursor: 'pointer', height: 136, width: 136}} />
        <List split={false} rootClassName={s['steps']}>
          <List.Item className={s['custom-list-item']} key={`first ${isPageFocused}`}>
            <AntText size={'body'} weight={'medium'} color={'contentMain'} style={!isPageFocused ? {color: 'var(--accent-main)', padding: 0} : {padding: 0}}>
              {t('scannerCellPage.steps.first')}
            </AntText>
          </List.Item>
          <List.Item className={s['custom-list-item']} key={`second ${isPageFocused}`}>
            <AntText size={'body'} weight={'medium'} color={'contentMain'} style={isPageFocused && !scanInstance?.id ? {color: 'var(--accent-main)', padding: 0} : {padding: 0}}>
              {t('scannerCellPage.steps.second')}
            </AntText>
          </List.Item>
          <List.Item className={s['custom-list-item']} key={`third ${isPageFocused}`}>
            <AntText size={'body'} weight={'medium'} color={'contentMain'} style={isPageFocused && scanInstance?.id && orders?.length < 1 ? {color: 'var(--accent-main)', padding: 0} : {padding: 0}}>
              {t('scannerCellPage.steps.third')}
            </AntText>
          </List.Item>
          <List.Item className={s['custom-list-item']} key={`fourth ${isPageFocused}`}>
            <AntText size={'body'} weight={'medium'} color={'contentMain'} style={isPageFocused && scanInstance?.id && orders?.length > 0 ? {color: 'var(--accent-main)', padding: 0} : {padding: 0}}>
              {t('scannerCellPage.steps.fourth')}
            </AntText>
          </List.Item>
        </List>
      </section>
      <section className={s['manual-set']}>
        <div className={s['search-cell']}>
          <AntLabel required>{t('scannerCellPage.manual.cell')}</AntLabel>
          <CellAsyncSelect key={`${scanInstance?.instance} ${isConfirmModalOpen}`}
                            success={scanInstance?.instance && !isConfirmModalOpen && t('scannerCellPage.manual.cellChosen')}
                            error={error?.scan}
                            defaultValue={isConfirmModalOpen ? null : scanInstance?.instance}
                            readOnly={false}
                            isLoading={false}
                            onChange={handleSelectCell}
          />
        </div>
      </section>
      <section className={s['table-section']}>
        {
          scanInstance?.id && (
            <ScanTable
              key={orders}
              isConfirmModalOpen={isConfirmModalOpen}
              instance={scanInstance}
              setError={setError}
              tableLoading={tableLoading}
              orders={orders}
              setOrders={setOrders}
              setScanInstance={setScanInstance}
              scanInstance={scanInstance}
              fetchAllData={updateAllOrders}
            />
          )
        }
      </section>
    </div>
  );
};

export default ScannerView;