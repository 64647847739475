import {useLocation} from 'react-router-dom'
import React, {useState} from 'react'
import {observer} from 'mobx-react-lite'

import './Sidebar.scss'
import useAuth from '@/common/hooks/useAuth'
import {Roles} from '@/common/constants/roles'
import UserProfile from './components/UserCard'
import NavItem from './components/NavItem'
import SoftLogo from '@/assets/icons/logo/soft_top_logo.svg'
import { t } from 'i18next';
import logo from '@/assets/icons/logo/Logo.svg';
import textDown from '@/assets/icons/logo/Craft.svg';
import {ReactComponent as OrderLogo} from '@/assets/icons/sideBar/truck-fast.svg';
import {ReactComponent as Main} from '@/assets/icons/sideBar/home.svg';
import {ReactComponent as Reports} from '@/assets/icons/sideBar/reports.svg';
import {ReactComponent as Couriers} from '@/assets/icons/sideBar/courier.svg';
import {ReactComponent as User} from '@/assets/icons/sideBar/user.svg';
import {ReactComponent as ScanCourier} from '@/assets/icons/sideBar/scan-courier.svg';
import {ReactComponent as ScanCell} from '@/assets/icons/sideBar/scan-box.svg';
import {ReactComponent as ScanThirdParty} from '@/assets/icons/sideBar/scan-sms.svg';
import AntText from '@/components/controls/text/antText';

const Path = [
  {label : 'sidebar.home', path: '/', icon: Main, roles: [Roles.Client]},
  {label : 'sidebar.orders', path: '/orders', icon: OrderLogo, roles: [], header: 'sidebar.orders' },
  {label : 'sidebar.scannerCell', path: '/scanner-cell', icon: ScanCell, roles: [Roles.Manager, Roles.Admin], header: 'sidebar.qr' },
  {label : 'sidebar.scannerCourier', path: '/scanner-courier', icon: ScanCourier, roles: [Roles.Manager, Roles.Admin] },
  {label : 'sidebar.scannerThirdParty', path: '/scanner-third-party', icon: ScanThirdParty, roles: [Roles.Manager, Roles.Admin] },
  {label : 'sidebar.couriers', path: '/couriers', icon: Couriers, roles: [Roles.Manager, Roles.Admin], header: 'sidebar.handbooks' },
  {label : 'sidebar.clients', path: '/client-handbook', icon: User, roles: [Roles.Manager, Roles.Admin] },
  {label : 'sidebar.reports', path: '/reports', icon: Reports, roles: [Roles.Manager, Roles.Admin], header: 'sidebar.reports' },
]

const Sidebar = observer(() => {
    const { user, signOut, hasRole, hasAnyRole } = useAuth()
    const { pathname } = useLocation()
    const [showNav, setShowNav] = useState(true);

    return (
        <div id="nav-bar" style={{width: !showNav ? '261px' : '82px'}}>
            <input id="nav-toggle" type="checkbox"
                   readOnly={true}
                   checked={showNav}
            />
            <div id="nav-header">
              <div className='logo'>
                <img src={logo} className="logo-img" alt='Logo' />
                <div className={`logo-body ${showNav && 'd-hide'}`}>
                  <img src={SoftLogo} className={`textUp ${showNav && 'd-none'}`} alt='Soft' />
                  <img src={textDown} className={`textDown ${showNav && 'opacity-0'}`} alt='Craft' />
              </div>
              <label htmlFor="nav-toggle" onClick={() => setShowNav(!showNav)}>
                <span id="nav-toggle-burger"></span>
              </label>
                <hr/>
            </div>
            </div>
            <div id="nav-content" style={{marginTop: '20px'}}>
              {
                Path.map(p => {
                  const Icon = p.icon;
                  const isCurrent = pathname === p.path;
                    return (<>
                        {
                          hasAnyRole(p.roles) && p?.header && (
                            <AntText weight={'medium'} className={'nav-text'} style={{ whiteSpace: 'nowrap' }}>{t(p.header)}</AntText>
                          )
                        }
                        <div className={`nav-button ${isCurrent && 'nav-button-active'}`}>
                          <NavItem
                            text={t(p.label)}
                            icon={<Icon style={{
                              color: isCurrent ? '#7B57DF' : 'var(--content-main)',
                              marginLeft: isCurrent ? '26px' : '30px',
                              width: '24px',
                              height: '24px',
                            }} />}
                            currentPath={pathname}
                            toPath={p.path}
                            show={hasAnyRole(p.roles)}
                            showNav={showNav}
                          />
                        </div>
                      </>
                    );
                  }
                )
              }
              <div id='nav-content-highlight'></div>
            </div>

          <div className='nav-footer' id='nav-footer'>
            <UserProfile showNav={showNav} user={user} hasRole={hasRole} pathname={pathname} onSignOut={signOut} />
          </div>
        </div>
    )
})

export default Sidebar
