import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';


import './index.scss'
import OrderImportTable from '@/pages/order-import/components/OrderImportTable';
import useImport from '@/common/hooks/useImport';
import { CityService } from '@/common/api/CityService';
import doneIcon from '@/assets/icons/import/done.svg'
import errorIcon from '@/assets/icons/import/error.svg'
import AntDatePicker from '@/components/controls/antDatePicker/antDatePicker'
import AntLabel from '@/components/controls/antLabel/antLabel'
import dayjs from 'dayjs'

const {Title} = Typography

const OrderImportViewPage = observer(() => {
  const {t} = useTranslation()
  const {importForm} = useImport()
  const [arriveDate, setArriveDate] = useState<string>(dayjs().toString())
  const { data: citiesData, refetch } = useQuery(['getCitiesForImportPage'],
    () => CityService.getAll({ size: 10000 }),
    {
      enabled: false,
    },
  );

  const handleChangeDate = (_, dateString: string) => {
    setArriveDate(dateString)
    importForm.arriveDate = dateString
  }

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <div className='orders-import'>
      {
        (importForm?.orders?.length - importForm?.brokenOrders?.length) > 0 && (
          <>
            <Title level={1} style={{marginTop: '1rem'}}>{t('ordersImportPage.title')} ({importForm?.orders?.length})</Title>
            <div className={'d-flex align-items-center mb-3'}>
              {
                importForm?.brokenOrders?.length > 0 ? (
                  <img style={{width: 32, marginRight: 8}} src={errorIcon} alt='' />
                ) : (
                  <img style={{width: 32, marginRight: 8}} src={doneIcon} alt='' />
                )
              }
              <div className={'d-flex flex-column'}>
                <Title
                  level={3}
                  style={{margin: '0', fontSize: '1rem', color: importForm?.brokenOrders?.length > 0 ? '#EB5769' : '#5DC983', fontWeight: 500}}
                >{t('ordersImportPage.total.successfullyLoaded')}
                  <span
                    style={{fontWeight: 700}}
                  > {importForm?.orders?.length - importForm?.brokenOrders?.length} </span>
                  {t('ordersImportPage.total.of')}
                  <span
                    style={{fontWeight: 700}}
                  > {importForm?.orders?.length} </span>
                  </Title>
                {importForm?.brokenOrders?.length > 0 && (
                  <Title
                    level={3}
                    style={{margin: '0', fontSize: '1rem', fontWeight: '500', color: importForm?.brokenOrders?.length > 0 ? '#EB5769' : '#5DC983'}}
                  >{t('ordersImportPage.total.error')}</Title>
                )}
              </div>
              {importForm.type !== 'Alseko' && (
                <div className="choose-date-form">
                  <AntLabel required>{t('ordersImportPage.arriveDate.label')}</AntLabel>
                  <div className={'choose-date'}>
                    <AntDatePicker
                      key={`arrive-date-picker`}
                      error={arriveDate === '' && t('ordersImportPage.arriveDate.error')}
                      rootClassName={'date-picker'}
                      onChange={handleChangeDate}
                      format={"YYYY-MM-DD"}
                      success={''}
                      defaultValue={dayjs(arriveDate)}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )
      }

      {citiesData?.cities && <OrderImportTable cities={citiesData?.cities} fetchCities={refetch}/>}
    </div>
  );
});

export default OrderImportViewPage;