import React, { FC, useState } from 'react';
import { Modal, Table, TableColumnsType } from 'antd';
import { OrderItem, OrderScanTableItem } from '@/common/models/order';
import { useTranslation } from 'react-i18next';
import AntParagraph from '@/components/controls/paragraph/AntParagraph';
import AntText from '@/components/controls/text/antText';
import { convertToTimeZone, getDeliveryPartyCity } from '@/common/utils/utils';
import { Formats } from '@/common/constants/dateFormat';
import s from '../index.module.scss';
import Text from '@/components/controls/text/antText';
import { Link } from 'react-router-dom';
import useAlert from '@/common/hooks/useAlert';
import AntHeading from '@/components/controls/heading/antHeading';
import { Cell } from '@/common/models/cell';


const ScanConfirmModal: FC<ScanConfirmModalProps> = ({ cell, orders, isConfirmModalOpen, setIsConfirmModalOpen, clearPage }) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    curr: 1,
    size: 20,
  });
  const handlePageChange = (page: number, size: number) => {
    setPagination({
      curr: page,
      size: size
    })
  }

  const {openNotification} = useAlert()

  const columns: TableColumnsType<OrderScanTableItem> = [
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          №
        </Text>
      ),
      key: 'no',
      width: 50,
      render: (_: any, record: OrderScanTableItem, index) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {
            (pagination.curr - 1) * pagination.size + index + 1
          }
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.id')}
        </Text>
      ),
      dataIndex: 'id',
      width: 92,
      className: s['scan-table-td'],
      render: (_: any, record: OrderScanTableItem) => {
        return <Link to={`/orders?id=${record?.id}`} target={'_blank'} style={{textDecorationColor: 'var(--accent-main)'}}>
          <Text weight={'regular'} size={'small'} color={'accentMain'}>
            {record?.id}
          </Text>
        </Link>
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.Receiver')}
        </Text>
      ),
      dataIndex: 'receiverName',
      width: 'auto',
      className: s['scan-table-td'],
      render: (_: any, record: OrderScanTableItem) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {record?.receiverName}
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.status')}
        </Text>
      ),
      width: 194,
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: OrderItem) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {t(`orderStatus.${record?.status}`, {from_city: getDeliveryPartyCity(record?.deliverySender), to_city: getDeliveryPartyCity(record?.deliveryReceiver)})}
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.route')}
        </Text>
      ),
      width: 70,
      dataIndex: 'route',
      key: 'route',
      render: (_: any, record: OrderItem) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {record?.route}
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.cell')}
        </Text>
      ),
      width: 70,
      dataIndex: 'cell',
      key: 'cell',
      render: (_: any, record: OrderItem) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {record?.cell}
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.createDate')}
        </Text>
      ),
      dataIndex: 'createDate',
      width: 150,
      className: s['scan-table-td'],
      render: (_: any, record: OrderScanTableItem) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {convertToTimeZone(record?.createDate, false, Formats.DATE_DDMMMMYYYY, true)}
        </Text>;
      },
    },
  ]

  const tableData = orders?.map(order => ({
    ...order,
    receiverName: `${order?.deliveryReceiver?.company?.name},  ${order?.deliveryReceiver?.address?.addressString}`,
    cell: cell?.name,
  }))

  const handleModalClose = () => {
    clearPage()
    openNotification('success', t('scannerCellPage.success.assignCell.header', {cell: cell?.name}), t('scannerCellPage.success.assignCell.text'), 0)
    setIsConfirmModalOpen(false)
  }

  return (
    <Modal width={880}
           height={800}
           className={s['modal']}
           title={<AntHeading size={'h3'}>{t('scannerCellPage.modal.header', { cell: cell?.name })}</AntHeading>}
           open={isConfirmModalOpen}
           cancelButtonProps={{style: {display: 'none'}}}
           okText={<AntText color={'#fff'} size={'body'} weight={'semiBold'}>OK</AntText>}
           okButtonProps={{className: s['ok-button']}}
           onOk={handleModalClose}
           onCancel={handleModalClose}
    >
      <AntParagraph style={{marginTop: '25px'}}>
        <AntText color={'contentMain'} size={'body'} weight={'medium'}>{t('scannerCellPage.modal.body.cell')}</AntText>
        <AntText color={'contentMain'} size={'body'}
                 weight={'regular'}>{`${cell?.name}`}</AntText>
      </AntParagraph>
      <AntParagraph style={{marginBottom: '25px'}}>
        <AntText color={'contentMain'} size={'body'} weight={'medium'}>
          {t('scannerCellPage.modal.body.assigned.start')}
        </AntText>
        <AntText color={'contentMain'} size={'body'} weight={'regular'}>
          {t('scannerCellPage.modal.body.assigned.end', { length: orders?.length })}
        </AntText>
      </AntParagraph>
      <Table
        columns={columns}
        dataSource={tableData}
        className={s['modal-table']}
        components={{
          table: (props) =>
            <div className={s['modal-table-wrapper']}>
              <table {...props} className={s['custom-scan-table']} />
            </div>,
          body: {
            cell: (props) => {
              return <DefaultScanCell className={s['scan-table-cell']} {...props} />;
            },
          },
        }}
        pagination={{
          current: pagination.curr,
          pageSize: pagination.size,
          total: tableData.length,
          defaultCurrent: pagination.curr,
          locale: {
            items_per_page: ` ${t('pagination.orders')}`,
            jump_to: t('pagination.jump_to'),
            page: t('pagination.page'),
            prev_page: t('pagination.prev_page'),
            next_page: t('pagination.next_page'),
          },
          showTotal: (total, range) => (
            <>
              {range[0]}-{range[1]} {t('pagination.from')} {total} {t('pagination.orders')}
            </>
          ),
          position: ['bottomLeft'],
          pageSizeOptions: ['10', '20', '50', '100'],
          onChange: handlePageChange,
        }}

      >
      </Table>
    </Modal>
  );
}

const DefaultScanCell = ({ children, record, form, ...restProps }) => {
  return <td {...restProps}>{children}</td>;
};

interface ScanConfirmModalProps {
  cell: Cell,
  orders: OrderItem[],
  estimatedDate: string,
  isConfirmModalOpen: boolean,
  setIsConfirmModalOpen: (isOpen: boolean) => void,
  clearPage: () => void
}

export default ScanConfirmModal;