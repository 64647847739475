import React from 'react'
import i18n from 'i18next'
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { ReactComponent as UserImage } from '@/assets/icons/sideBar/avatar.svg'
import { ReactComponent as EditIcon } from '@/assets/icons/sideBar/edit.svg'
import { ReactComponent as LogoutIcon } from '@/assets/icons/sideBar/logout.svg'
import {Roles} from "@/common/constants/roles";
import { User } from '@/common/models/user'
import AntParagraph from '@/components/controls/paragraph/AntParagraph';
import '../Sidebar.scss'
import { useNavigate } from 'react-router-dom';

const t = i18n.t

const UserProfile = ({ showNav, user, hasRole, onSignOut }: UserProfileProps) => {
    const navigate = useNavigate()

    if (!user) {
        return
    }

    const items = [
        ...(hasRole(Roles.Client) ? [{
            key: 'edit',
            label: t('sidebar.updateData'),
            icon: <EditIcon />,
            onClick: () => navigate('/onboard'),
        }] : []),
        {
            key: 'logout',
            label: t('sidebar.logout'),
            icon: <LogoutIcon />,
            onClick: () => onSignOut(),
        },
    ];

    return (
      <>
          <div className='user-card' style={{ display: 'flex', width: '100%', alignItems: 'flex-start', flexDirection: showNav ? 'column' : 'row' }}>
              <div style={{ width: '92%', display: 'flex', justifyContent: 'flex-start'}}>
                  <UserImage />
                  <div style={{ display: !showNav ? 'inline-block' : 'none', marginLeft: '8px', }}>
                      <AntParagraph style={{whiteSpace: 'nowrap'}} weight={'regular'} color={'contentMain'}>{user.username}</AntParagraph>
                      <AntParagraph style={{whiteSpace: 'nowrap'}} weight={'medium'} color={'contentDop'}>
                          {user.roles.map((role, index) => (
                            <span key={index} className='fw-medium'>
                            {t(roleLabels[role])}
                        </span>
                          ))}
                      </AntParagraph>
                  </div>
              </div>
              <Dropdown overlayClassName={'user-card-dropdown'} placement={'topLeft'} menu={{ items }} trigger={['click']}>
                  <EllipsisOutlined style={{ fontSize: 30, cursor: 'pointer', color: 'var(--accent-main)', marginLeft: '10px' }} />
              </Dropdown>
          </div>
      </>
    )
}

const roleLabels = {
    [Roles.Manager]: 'roles.Manager',
    [Roles.Client]: 'roles.Client',
    [Roles.Admin]: 'roles.Admin',
}

interface UserProfileProps {
    showNav: boolean,
    user: User,
    onSignOut: () => void,
    hasRole: (role: string) => boolean,
    pathname: string,
}

export default UserProfile