import React, { FC, useState } from 'react';
import { Popconfirm, Table, Row, Col, Pagination } from 'antd';
import Text from '@/components/controls/text/antText';
import { handbookTableClient } from '@/common/models/handbook';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDate } from 'date-fns';
import { formatToTimeStamp, getDeliveryPartyCity } from '@/common/utils/utils';
import { Formats } from '@/common/constants/dateFormat';
import { ru } from 'date-fns/locale';
import { OrderItem } from '@/common/models/order';
import { ReactComponent as DeleteIcon} from '@/assets/icons/trash.svg'
import AntText from '@/components/controls/text/antText';
import AntButton from '@/components/buttons/antButton';
import useAlert from '@/common/hooks/useAlert';
import s from '../index.module.scss';

const ScanTable: FC<ScanTableProps> = ({ orders, setOrders, tableLoading, updateAllOrders, isConfirmModalOpen}) => {
  const { t } = useTranslation()
  const { openNotification } = useAlert()


  const [pagination, setPagination] = useState({
    curr: 1,
    size: 20,
  });


  let paginatedData = orders.slice(
    (pagination.curr - 1) * pagination.size,
    pagination.curr * pagination.size
  );

  const handleDelete = (id) => {
    setOrders(orders.filter((order: OrderItem) => order?.id !== id));
    openNotification('success', t('scannerThirdPartyPage.success.deleteOrder.header', {id: id}), t('scannerThirdPartyPage.success.deleteOrder.text'))
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setPagination(prev => ({
      ...prev,
      curr: page,
      size: pageSize,
    }));
  };


  const columns = [
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          №
        </Text>
      ),
      width: 50,
      key: 'no',
      render: (_: any, record: handbookTableClient, index) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {
            (pagination.curr - 1) * pagination.size + index + 1
          }
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.id')}
        </Text>
      ),
      width: 92,
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      render: (_: any, record: handbookTableClient) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          <Link to={`/orders?id=${record?.id}`} target={'_blank'}>
            {record?.id}
          </Link>
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.createDate')}
        </Text>
      ),
      width: 150,
      dataIndex: 'createDate',
      key: 'createDate',
      sorter: {
        compare: (a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime(),
      },
      render: (_: any, record: handbookTableClient) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {
            record?.createDate ? (
              formatDate(formatToTimeStamp(record?.createDate), Formats.DATE_DDMMMYYYY_TIME, { locale: ru })
            ) : ''
          }
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.Sender')}
        </Text>
      ),
      dataIndex: 'sender',
      key: 'sender',
      sorter: (a, b) => `${a?.deliverySender?.company?.name}, ${a?.deliverySender?.address?.addressString}`.localeCompare(`${b?.deliverySender?.company?.name}, ${b?.deliverySender?.address?.addressString}`, undefined, { sensitivity: 'base' }),
      render: (_: any, record: OrderItem) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {`${record?.deliverySender?.company?.name}, ${record?.deliverySender?.address?.addressString}`}
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.Receiver')}
        </Text>
      ),
      dataIndex: 'receiver',
      key: 'receiver',
      sorter: (a, b) => `${a?.deliveryReceiver?.company?.name}, ${a?.deliveryReceiver?.address?.addressString}`.localeCompare(`${b?.deliveryReceiver?.company?.name}, ${b?.deliveryReceiver?.address?.addressString}`, undefined, { sensitivity: 'base' }),
      render: (_: any, record: OrderItem) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {`${record?.deliveryReceiver?.company?.name}, ${record?.deliveryReceiver?.address?.addressString}`}
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.route')}
        </Text>
      ),
      width: 90,
      dataIndex: 'route',
      key: 'route',
      sorter: (a, b) => a?.route?.localeCompare(b?.route, undefined, { sensitivity: 'base' }),
      render: (_: any, record: OrderItem) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {record?.route}
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.cell')}
        </Text>
      ),
      width: 80,
      dataIndex: 'cell',
      key: 'cell',
      sorter: (a, b) => a?.cell?.localeCompare(b?.cell, undefined, { sensitivity: 'base' }),
      render: (_: any, record: OrderItem) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {record?.cell}
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.status')}
        </Text>
      ),
      width: 200,
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => t(`orderStatus.${a?.status}`).localeCompare(t(`orderStatus.${b?.status}`), undefined, { sensitivity: 'base' }),
      render: (_: any, record: OrderItem) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {t(`orderStatus.${record?.status}`, {from_city: getDeliveryPartyCity(record?.deliverySender), to_city: getDeliveryPartyCity(record?.deliveryReceiver)})}
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('ordersImportPage.table.columns.delete')}
        </Text>
      ),
      width: 72,
      key: 'no',
      render: (_: any, record: OrderItem) => {
        return <Popconfirm
          overlayStyle={{maxWidth: '280px'}}
          okButtonProps={{
            style: {
              backgroundColor: '#E9F7EE'
            },
          }}
          cancelButtonProps={{
            style: {
              backgroundColor: '#FDEEF0'
            },
          }}
          okText={
            <AntText size={'small'} color={'accentGreen'} weight={'regular'}>{t('scannerThirdPartyPage.confirm.ok')}</AntText>
          }
          cancelText={
            <AntText size={'small'} color={'accentRed'} weight={'regular'}>{t('scannerThirdPartyPage.confirm.no')}</AntText>
          }
          title={
            <>
              <AntText size={'small'} weight={'bold'}>{t('scannerThirdPartyPage.confirm.deleteHeader')}</AntText>
              <br />
              <AntText size={'small'} weight={'regular'}>{t('scannerThirdPartyPage.confirm.text')}</AntText>
            </>
          }
          onConfirm={() => handleDelete(record.id)}
        >
          <DeleteIcon style={{ color: 'var(--accent-red)', cursor: 'pointer' }} />
        </Popconfirm>
      },
    },
  ]

  return (
    <Table
      loading={tableLoading}
      className={s['custom-scan-table']}
      columns={columns}
      dataSource={isConfirmModalOpen ? [] : paginatedData}
      pagination={false}
      locale={{
        emptyText: t('scannerThirdPartyPage.emptyTable'),
      }}
      footer={() => (
        <>
          {orders?.length > 0 && (
            <Row justify="space-between" align='middle' style={{ marginTop: 16 }}>
              <Col>
                <Pagination
                  current={pagination.curr}
                  total={orders?.length}
                  showTotal={(total, range) => (
                    <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                      <Col>
                        <div>
                          {range[0]}-{range[1]} {t('pagination.from')} {total} {t('pagination.orders')}
                        </div>
                      </Col>
                    </Row>
                  )}
                  locale={{
                    items_per_page: ` ${t('pagination.orders')}`,
                    jump_to: t('pagination.jump_to'),
                    page: t('pagination.page'),
                    prev_page: t('pagination.prev_page'),
                    next_page: t('pagination.next_page'),
                  }}
                  pageSize={pagination.size}
                  showSizeChanger={true}
                  pageSizeOptions={['5', '10', '20', '50']}
                  showQuickJumper={true}
                  onChange={handlePageChange}
                />
              </Col>
              <Col>
                <Popconfirm
                  overlayStyle={{maxWidth: '280px'}}
                  okButtonProps={{
                    style: {
                      backgroundColor: 'var(--accent-green-light)'
                    },
                  }}
                  cancelButtonProps={{
                    style: {
                      backgroundColor: 'var(--accent-red-light)'
                    },
                  }}
                  okText={
                    <AntText size={'small'} color={'accentGreen'} weight={'regular'}>{t('scannerThirdPartyPage.confirm.ok')}</AntText>
                  }
                  cancelText={
                    <AntText size={'small'} color={'accentRed'} weight={'regular'}>{t('scannerThirdPartyPage.confirm.no')}</AntText>
                  }
                  title={
                    <>
                      <AntText size={'small'} weight={'bold'}>{t('scannerThirdPartyPage.confirm.assignHeader')}</AntText>
                      <br />
                      <AntText size={'small'} weight={'regular'}>{t('scannerThirdPartyPage.confirm.text')}</AntText>
                    </>
                  }
                  onConfirm={updateAllOrders}
                >
                  <AntButton>{t('scannerThirdPartyPage.confirm.button')}</AntButton>
                </Popconfirm>
              </Col>
            </Row>
          )}
        </>
      )}

    />
  );
};

interface ScanTableProps {
  orders: OrderItem[],
  setOrders: (orders: OrderItem[]) => void,
  tableLoading: boolean,
  updateAllOrders: () => void,
  isConfirmModalOpen: boolean,
}

export default ScanTable;