import { makeAutoObservable } from "mobx"
import { Persistable } from "./persistable"
import { CourierStatusMode } from '@/common/constants/statuses';

export const courierDeliveryTypeOptions = {
    [CourierStatusMode.PICKUP]: [
        { value: 'deliverySender.address.addressString', label: 'telegram.pages.orderAction.deliverySenderAddress' },
        { value: 'id', label: 'telegram.pages.orderAction.orderId' },
        { value: 'deliverySender.company.name', label: 'telegram.pages.orderAction.deliverySender' },
    ],
    [CourierStatusMode.DELIVERY]: [
        { value: 'deliveryReceiver.address.addressString', label: 'telegram.pages.orderAction.deliveryReceiverAddress' },
        { value: 'id', label: 'telegram.pages.orderAction.orderItemId' },
        { value: 'deliveryReceiver.company.name', label: 'telegram.pages.orderAction.deliveryReceiver' },
    ]
}

class CourierStore implements Persistable {

    order: any
    isPreparing: boolean = true
    telegramData: any
    searchQuery: string = ''
    selectionTab: string = CourierStatusMode.DELIVERY
    searchType: string = courierDeliveryTypeOptions[CourierStatusMode.DELIVERY][0]?.value


    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.loadFromLocalStorage()
    }

    setOrder(order: any) {
        this.order = order
        localStorage.setItem('order', JSON.stringify(this.order))
    }

    setIsPreparing(isPreparing: boolean) {
        this.isPreparing = isPreparing
    }

    setTelegramData(data: any) {
        this.telegramData = data
    }

    setSearchType(value: string) {
        this.searchType = value
        localStorage.setItem('search-type', JSON.stringify(this.searchType))
    }

    setSearchQuery(value: string) {
        this.searchQuery = value
        localStorage.setItem('search-query', JSON.stringify(this.searchQuery))
    }

    setSelectionTab(value: string) {
        this.selectionTab = value
        localStorage.setItem('selection-tab', JSON.stringify(this.selectionTab))
    }

    loadFromLocalStorage(): void {
        const order = localStorage.getItem('order')
        const telegramData = localStorage.getItem('user-data')
        const searchType = localStorage.getItem('search-type')
        const searchQuery = localStorage.getItem('search-query')
        const selectionTab = localStorage.getItem('selection-tab')

        if (order) {
            this.order = JSON.parse(order)
        }

        if (telegramData) {
            this.telegramData = JSON.parse(telegramData)
        }

        if (searchType) {
            this.searchType = JSON.parse(searchType)
        }

        if (searchQuery) {
            this.searchQuery = JSON.parse(searchQuery)
        }

        if (selectionTab) {
            this.selectionTab = JSON.parse(selectionTab)
        }
    }
}

const courierStore = new CourierStore()

export default courierStore
