import requests from '@/common/api/apiAgent';


export const CellService = {
  getAll: async () => {
    return requests.get('/cells')
  },

  getById: async (id: string | number) => {
    return requests.get(`/cells/${id}`)
  }

}