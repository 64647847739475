import React, { CSSProperties, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@/common/models/util';
import { AsyncPaginate } from 'react-select-async-paginate';
import AntText from '@/components/controls/text/antText';
import { StylesConfig } from 'react-select';
import { useQuery } from 'react-query';
import { CellService } from '@/common/api/CellService';
import { Cell } from '@/common/models/cell';
import useAlert from '@/common/hooks/useAlert';

const CellAsyncSelect = ({
                           styles = {}, error, success, onChange = () => {}, name, defaultValue,
                           isLoading = false, readOnly = false }: CellAsyncSelectProps) => {
  const hasMount = useRef(false)
  const { t } = useTranslation()
  const { openNotification } = useAlert()

  const { data: cells, refetch: getCells } = useQuery(['get-all-cells'], async () => CellService.getAll(),
    {
      enabled: false,
      cacheTime: 0,
      onError: () => {
        openNotification('error', t('api.messages.error'), error?.response?.body?.errorMessage || t('api.messages.serviceDown'))
      }
    }
  )

  const [cell, setCell] = useState(defaultValue?.value ? defaultValue : null)
  const buildSelectOption = (item: Cell) => ({
    value: item?.id,
    label: item?.name,
  })
  if(hasMount.current === false){
    if(defaultValue){
      setCell(buildSelectOption(defaultValue))
    }
    hasMount.current = true
  }

  const loadOptions = async (search: string) => {
    let freshCells: Cell[];

    if(!cells) {
      const { data } = await getCells();
      freshCells = data
    } else {
      freshCells = cells
    }

    const options: SelectOption[] = freshCells.map(buildSelectOption)
    return {
      options: search?.trim() ? options.filter((cell: SelectOption) => cell?.label?.includes(search?.trim()?.toUpperCase())) : options,
    };
  };

  const onOptionSelect = async (option: SelectOption) => {
    setCell(option)
    onChange(option)
  }

  const defaultSelectStyles = {
    control: (provided: CSSProperties, { isFocused, selectProps: { success, error } }) => {
      return {
        ...provided,
        fontSize: '14px !important',
        borderColor: isFocused ? 'var(--accent-main)' : error ? '#EB5769' : success ? '#5DC983' : 'var(--content-dop-light)',
        borderRadius: '6px',
        height: '32px !important',
        minHeight: '32px !important',
        alignItems: 'center !important',
        transition: 'all 0.2s ease',
        boxShadow: isFocused ? '0 0 0 2px var(--accent-main-light)' : 'none',
        '&:hover': {
          borderColor: 'var(--accent-main)',
        },
      };
    },
    menuList: (provided) => ({
      ...provided,
      maxHeight: '135px',
      '::-webkit-scrollbar': {
        width: '6px',
      },
      '::-webkit-scrollbar-thumb': {
        background: 'var(--accent-main-light)',
        borderRadius: '4px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f0f0f0',
      },
    }),
    singleValue: (provided: CSSProperties, { data }) => {
      return {
        ...provided,
        color: data?.value ? 'var(--content-main)' : 'var(--content-dop-light)',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: 'normal',
      };
    },
    menu: (provided: CSSProperties) => ({
      ...provided,
      zIndex: 2,
      color: 'var(--content-main)',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 'normal',
    }),
    input: (provided: CSSProperties) => ({
      ...provided,
      color: 'var(--content-main)',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 'normal',
      padding: '0 !important',
      margin: '0 !important',
    }),
    valueContainer: (provided: CSSProperties) => ({
      ...provided,
      padding: '0 !important',
      margin: '0 12px !important',
    }),
    indicatorSeparator: (provided: CSSProperties) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided: CSSProperties) => ({
      ...provided,
      height: '32px !important',
      paddingTop: '5px',
    }),
    indicatorsContainer: (provided: CSSProperties) => ({
      ...provided,
      minHeight: '32px !important',
      height: '32px !important',
    }),
    placeholder: (provided: CSSProperties) => ({
      ...provided,
      color: 'var(--content-dop-light)',
    })
  }


  return (
    <>
      <AsyncPaginate
        defaultValue={defaultValue}
        placeholder={t('ordersImportPage.table.emptyCell')}
        value={cell}
        success={success}
        error={error}
        blurInputOnSelect={true}
        defaultOptions
        styles={{ ...defaultSelectStyles, ...styles }}
        name={`city-${name}`}
        isDisabled={readOnly}
        isLoading={isLoading}
        isSearchable={true}
        loadOptions={loadOptions}
        onChange={onOptionSelect}
        noOptionsMessage={() => t('api.messages.noData')}
      />
      {error && <AntText size={'small'} weight={'medium'} color={'accentRed'} style={{whiteSpace: 'nowrap'}}>{error}</AntText>}
      {success && <AntText size={'small'} weight={'medium'} color={'accentGreen'}>{success}</AntText>
      }
    </>

  )
}

interface CellAsyncSelectProps {
  styles: StylesConfig
  onChange: (option: SelectOption) => void,
  name?: string,
  defaultValue?: SelectOption,
  isLoading?: boolean,
  readOnly?: boolean,
  error?: string;
  success?: string;
}

export default CellAsyncSelect;