export const ruScannerCellPage = {
  header: "Назначение ячейки",
  scanQRTitle: "Отсканируйте QR ячейки и заказов",
  emptyTable: 'Отсканируйте QR заказов, которые хотите назначить на выбранную ячейку',
  steps: {
    first: 'Шаг 1: Нажмите на иконку сканера или в любое место на странице, чтобы активировать сканирование',
    second: 'Шаг 2: Отсканируйте QR ячейки или выберите ячейку вручную из списка',
    third: 'Шаг 3: Отсканируйте QR заказов, которые хотите положить в выбранную ячейку',
    fourth: 'Шаг 4: Для подтверждения отсканируйте повторно QR ячейки или нажмите кнопку “Подтвердить”',
  },
  errors: {
    badQR: {
      header: 'Ошибка считывания QR-кода',
      text: 'Убедитесь, что QR-код правильный и повторите попытку, также вы можете вручную назначить ячейку через таблицу Заказов (на слове ссылка на таблицу с заказами)',
    },
    noOrders: {
      header: 'Вы не добавили заказы',
      text: 'Сначала отсканируйте хотя бы один заказ, чтобы назначить ячейку',
    },
    noCell: 'Ячейка не выбрана, отсканируйте QR ячейки или выберите из списка',
    noDateField: 'Не выбрана дата',
    noDateMessage: {
      header: 'Дата ожидаемой доставки не выбрана',
      text: 'Пожалуйста, укажите дату и подтвердите еще раз',
    },
    noFocus: {
      header: 'Вы потеряли фокус страницы',
      text: 'Нажмите в любое место на этой странице, чтобы снова активировать сканирование',
    },
    noOrdersFound: {
      header: 'Заказ с номером {{id}} не найден в системе',
      text: 'Проверьте правильность QR-кода или обратитесь в службу поддержки',
    },
    cellNotMatched: {
      header: 'QR ячейки не совпадает с выбранной ячейкой',
      text: 'Убедитесь, что QR-код принадлежит той же ячейке, которую вы выбрали ранее',
    },
    badResponse: {
      header: 'Ошибка сервера',
      text: 'Не удалось назначить ячейку'
    },
  },
  info: {
    orderExists: {
      header: 'Заказ №{{id}} уже добавлен',
      text: 'Заказ уже есть в списке',
    }
  },
  success: {
    addOrder: {
      header: 'Заказ №{{id}} добавлен',
      text: 'В список назначения заказов на ячейку',
    },
    assignCell: {
      header: 'Заказы успешно назначены на ячейку {{cell}}',
      text: 'Назначенные заказы можно найти в таблице Заказов, применив фильтр по выбранной ячейке',
    },
    deleteOrder: {
      header: 'Заказ №{{id}} успешно удален',
      text: 'Вы можете отсканировать его QR заново',
    }
  },
  manual: {
    cellChosen: 'Ячейка успешно выбрана',
    cell: 'Ячейка',
    estimatedDeliveryDate: 'Дата ожидаемой доставки',
    estimatedDeliveryDateChosen: 'Дата ожидаемой доставки успешно выбрана',
  },
  confirm: {
    deleteHeader: 'Вы уверены, что хотите удалить заказ?',
    assignHeader: 'Вы уверены, что хотите подтвердить?',
    text: 'Это действие нельзя отменить ',
    ok: 'Да, подтвердить',
    no: 'Отменить',
    button: 'Подтвердить',
  },
  modal: {
    header: 'Назначены заказы на ячейку {{cell}}',
    body: {
      cell: 'Ячейка: ',
      assigned: {
        start: 'Назначено: ',
        end: '{{length}} заказов'
      },
      date: 'Ожидаемая дата доставки: ',
    },
  }
}