import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, Modal, Table, Typography, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query'
import { ResponseError } from 'superagent'

import ImportTypeSelect from '@/pages/order-import/components/ImportTypeSelect';
import { getParsedOrders, hasEmptyFields, parseQazpost } from '@/common/utils/parsers';
import { importTypesOptions, TypeExtensionMap } from '@/pages/order-import/importTypesOptions';
import useImport from '@/common/hooks/useImport';
import { CityApiPageResponse } from '@/common/models/api/v0/address.dto';
import OrderService from '@/common/api/OrderService'
import { OrderItemPatchData } from '@/common/models/api/v0/order.dto'
import { UploadChangeParam } from 'antd/lib/upload';
import excelIcon from '@/assets/icons/import/excel.svg'
import pdfIcon from '@/assets/icons/import/pdf.svg'
import doneIcon from '@/assets/icons/import/done.svg'
import errorIcon from '@/assets/icons/import/error.svg'
import { formatDate } from 'date-fns'
import { Formats } from '@/common/constants/dateFormat'


const {Title, Paragraph, Text} = Typography

const { Dragger } = Upload;


const OrderImportFileModal: FC<OrderImportFileModalProps> = observer(({cities, handleNextStep, handleBackStep, handleClose}) => {
  const navigate = useNavigate()
  const {importForm, setImportForm} = useImport();
  const [isFileLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [qazpostUpload, setQazPostUpload] = useState<QazpostUpload>({ orderItems: [], isSuccess: false })
  const { mutate: sendPatchOrderItems, isLoading: isPatchLoading } = useMutation<any, ResponseError, OrderItemPatchData[]>(
    (orderItems) => OrderService.patchOrderItems({ data: orderItems }), 
    {
        onSuccess: () => window.location.reload(),
        onError: (error) => setError(error?.response?.body?.errorMessage || t('api.messages.serviceDown')),
    })

  const { mutateAsync: readFile } = useMutation<
    string | ArrayBuffer,
    Error,
    File
  >(
    (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          if (e.target?.result) {
            resolve(e.target.result);
            setError(null)
          } else {
            reject({message: t('ordersImportPage.forms.upload.error.format')});
          }
        };

        reader.onerror = (e) => {
          setError(t('ordersImportPage.forms.upload.error.format'))
          reject(e)
        }

        reader.readAsArrayBuffer(file);
      });
    },
    {
      onSettled: () => {
        setIsLoading(false);
      }
    }
  );

  const { mutateAsync: importOrders } = useMutation<void, ResponseError, UploadChangeParam>(['importFile'], async (info) => {
    const file = info.fileList.filter(file => file.uid === info.file.uid)[0].originFileObj;
    if (file?.type !== TypeExtensionMap[importForm?.type]?.value) {
      setError(t('ordersImportPage.forms.upload.error.format'));
      return;
    }
    setIsLoading(true)

    if (importForm?.type === 'Qazpost') {
      const fileContent = await readFile(file)
      const parsedFile = parseQazpost(fileContent)
      setQazPostUpload({ orderItems: parsedFile, isSuccess: true })
      return
    }
    const fileContent = await readFile(file)

    const parsingFile = TypeExtensionMap[importForm?.type]?.label === ".xls, .xlsx" ? fileContent : file
    const readyTableOrders = await getParsedOrders(parsingFile, cities, importForm?.type);
    setImportForm({
      ...importForm,
      arriveDate: formatDate(new Date(), Formats.DATE_YMD),
      orders: readyTableOrders,
      fileName: file?.name,
      fileSize: (file?.size / 1000).toString() + ' кб',
      brokenOrders: importForm?.orders?.filter(order => hasEmptyFields(order) === true),
      initialOrders: readyTableOrders?.map(orderItem => {
        return {
          key: orderItem?.id,
          id: orderItem?.id,
          createDate: orderItem?.createDate,
          Sender: orderItem?.deliverySender?.company?.name,
          Receiver: orderItem?.deliveryReceiver?.company?.name,
          city: cities.find(city => city?.id === orderItem?.deliveryReceiver?.address?.cityId)?.name || "",
          addressString: orderItem?.deliveryReceiver?.address?.addressString || "",
          addressFromFile: orderItem?.addressFromFile || "",
          addressComments: orderItem?.deliveryReceiver?.address?.addressComment || ""
        };
      })
    })
    setError(null)

  }, {
    onSettled: () => {
      setIsLoading(false);
    }
  })

  const props = {
    name: 'file',
    multiple: false,
    accept: TypeExtensionMap[importForm?.type]?.label,
    beforeUpload: () => {
      // Prevent automatic upload
      return false;
    },
    async onChange(info) {
      await importOrders(info)
    },
  };
  const { t } = useTranslation();

  const handleQazpostSave = () => {
    sendPatchOrderItems(qazpostUpload.orderItems)
  }

  const handleClearFile = () => {
    setImportForm({
      ...importForm,
      fileName: '',
      fileSize: '',
      orders: [],
      brokenOrders: [],
      initialOrders: []
    })
    setError(null)
  }

  return (
    <Modal
      open={importForm?.step === 1}
      width={600}
      onCancel={handleClose}
      className={"import-second-step"}
      title={<Title level={3}>{t('ordersImportPage.forms.title')}</Title>}
      footer={
        <div id={"second-step-footer"}>

          <Button
            className="footer-button"
            color={'danger'}
            variant={'solid'}
            onClick={() => {
              handleClearFile()
              handleBackStep()
            }}
          >{t('ordersImportPage.forms.back')}</Button>
          {importForm.type === 'Qazpost' ? (
            <Button
              loading={isFileLoading || isPatchLoading}
              disabled={!qazpostUpload.isSuccess || isPatchLoading}
              className="footer-button"
              type={"primary"}
              onClick={handleQazpostSave}
            >
              {t('ordersImportPage.forms.save')}
            </Button>
          ) : (
            <Button
              loading={isFileLoading}
              disabled={importForm?.fileName === "" || error !== null}
              className="footer-button"
              type={"primary"}
              onClick={() => navigate('/orders-import')}
            >
              {t('ordersImportPage.forms.load')}
            </Button>
          )}
        </div>
      }
    >
      <Title className={'second-step-title'} level={5}>{t("ordersImportPage.forms.type")}</Title>
      <ImportTypeSelect
        className={'import-dropdown'}
        disabled={true}
        style={{width: "45%"}}
        placeHolder={
          <Text>
            {t(importTypesOptions.find(option => option.value === importForm?.type)?.label)}
          </Text>
        }
      />
      {(importForm?.fileName) && (
        <div id='second-step-body'>
          <img className='file-img' src={TypeExtensionMap[importForm?.type]?.label === ".xls, .xlsx" ? (excelIcon) : (pdfIcon)} alt='icon' />
          <div className='file-body'>
            <div className='import-file-name'>{importForm?.fileName}</div>
            <div className='import-file-size'>{importForm?.fileSize}</div>
          </div>
          <img onClick={handleClearFile} className='import-file-status' src={error === null ? doneIcon : errorIcon} alt='icon' />
        </div>
      )}
      {(!importForm?.fileName && !qazpostUpload.isSuccess) && (
        <Dragger
          className='import-dragger'
          showUploadList={false}
          {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <div className="dragger-text">{t('ordersImportPage.forms.upload.body.drag')} <Text className="dragger-text" style={{color: 'var(--bs-primary)'}}>{t('ordersImportPage.forms.upload.body.click')}</Text></div>
          <p className="ant-upload-hint dragger-hint">{t('ordersImportPage.forms.upload.body.formats')} {TypeExtensionMap[importForm?.type]?.label}</p>
        </Dragger>
      )}
      {qazpostUpload.isSuccess && (
        <Table 
          dataSource={qazpostUpload.orderItems}
          columns={[
            { 
              title: t('ordersImportPage.forms.upload.table.orderItemId'),
              dataIndex: 'orderItemId',
              key: 'orderItemId'
            },
            { 
              title: t('ordersImportPage.forms.upload.table.thirdPartyTrackingNumber'),
              dataIndex: 'thirdPartyTrackingNumber',
              key: 'thirdPartyTrackingNumber'
            },
          ]} 
        />
      )}
      {error !== null && typeof error === "string" && (
        <div className='import-error'>
          <div className='import-error-body'>
            {error?.split('.').map((text, index) => (
              <Paragraph className={'error-text'} key={index} style={{fontWeight: `${index === 0 ? 'bold' : 'normal'}`}} type={'danger'}>{text} <Text type={'danger'} style={{fontWeight: 'bold'}}>{index > 0 ? TypeExtensionMap[importForm?.type]?.label : ""}</Text></Paragraph>
            ))}
          </div>
          <img onClick={handleClearFile} className='import-file-status' src={errorIcon} alt='icon' />
        </div>
      )}
    </Modal>
  );
})

interface OrderImportFileModalProps {
  cities: CityApiPageResponse,
  handleNextStep: () => void,
  handleBackStep: () => void,
  handleClose: () => void
}

type QazpostUpload = {
  orderItems: OrderItemPatchData[],
  isSuccess: boolean,
}

export default OrderImportFileModal;